import {Injectable} from '@angular/core';
import {ApiBase} from '@core/backend/ecommerce/api/api-base';
import {OrganizationRoleNameDTO} from '@core/interfaces/serious-vr/generated-models';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';

@Injectable()
export class OrganizationRoleNameApi extends ApiBase<OrganizationRoleNameDTO> {
  readonly apiController: string = 'organization-role';

  options(): Observable<GridData<OrganizationRoleNameDTO>> {
    return this.httpService.get(`${this.apiController}/options`);
  }
}
