import {PerformanceIndicatorType} from '@core/interfaces/serious-vr/enums';


export function getTranslationKey(type: PerformanceIndicatorType) {
  const prefix = 'PI_INDICATOR_TYPES.';

  switch (type) {
    case PerformanceIndicatorType.TimeIndicator:
      return `${prefix}TIME`;
    case PerformanceIndicatorType.TaskIndicator:
      return `${prefix}TASK`;
    case PerformanceIndicatorType.ErrorIndicator:
      return `${prefix}ERROR`;
    default:
      return `${prefix}UNKNOWN`;
  }
}
