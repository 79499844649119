import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {map, switchMap} from 'rxjs/operators';
import {IModelStateMessages} from '@core/backend/validation';
import {Observable, of, Subject} from 'rxjs';
import {ApplicationTaskData} from '@core/interfaces/ecommerce/application-task';
import {ApplicationSoftwareInfoService} from '@core/backend/ecommerce/services/application-software-info.service';
import {SvrComponent} from '@shared/base/svr.component';
import {SoftwareInfoDTO, ApplicationTaskDTO, ApplicationTaskGroupDTO} from '@core/interfaces/serious-vr/generated-models';
import {ApplicationTaskGroupData} from '@core/interfaces/ecommerce/application-task-group';

@Component({
  selector: 'svr-edit-application-task-component',
  templateUrl: './edit-application-task.component.html',
  styleUrls: ['./edit-application-task.component.scss'],
})
export class EditApplicationTaskComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'APPLICATIONS.TASKS.';

  applicationId: number;
  applicationTask: ApplicationTaskDTO;
  selectedMinVersion: number;
  selectedMaxVersion: number;

  form: FormGroup = null;
  softwareInfo$: Observable<SoftwareInfoDTO[]>;
  taskGroups$: Observable<ApplicationTaskGroupDTO[]>;

  public modelStateMessages: IModelStateMessages = {};

  get application() {
    return this.form.get('applicationId');
  }

  protected readonly unsubscribe$ = new Subject<void>();

  constructor(protected ref: NbDialogRef<number>,
              private fb: FormBuilder,
              private applicationTaskService: ApplicationTaskData,
              private applicationSoftwareVersion: ApplicationSoftwareInfoService,
              private applicationTaskGroupService: ApplicationTaskGroupData,
              toastrService: NbToastrService) {
    super(toastrService);
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      id: this.fb.control(undefined),
      key: this.fb.control('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)]),
      name: this.fb.control('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)]),
      description: this.fb.control('', [
        Validators.maxLength(250)]),
      applicationId: this.fb.control(undefined, [
        Validators.required]),
      minVersionId: this.fb.control(undefined),
      maxVersionId: this.fb.control(undefined),
      taskGroupId: this.fb.control(undefined),
    });
  }

  initData() {
    if (this.applicationTask) {
      this.form.setValue({
        ...this.form.value,
        id: this.applicationTask.id,
        key: this.applicationTask.key,
        name: this.applicationTask.name,
        description: this.applicationTask.description,
        applicationId: this.applicationTask.applicationId,
        minVersionId: this.selectedMinVersion = this.applicationTask.minVersion
          ? this.applicationTask.minVersion.id : null,
        maxVersionId: this.selectedMaxVersion = this.applicationTask.maxVersion
          ? this.applicationTask.maxVersion.id : null,
        taskGroupId: this.applicationTask.applicationTaskGroupId
          ? this.applicationTask.applicationTaskGroupId : null,
      });
    } else {
      this.form.setValue({
        ...this.form.value,
        applicationId: this.applicationId,
        taskGroupId: null,
      });
    }
  }

  ngOnInit() {
    this.initData();
    this.softwareInfo$ = this.call(this.applicationSoftwareVersion.list(1, 100, this.applicationId)
      .pipe(
        switchMap(d => of(d.items)),
      ));
    this.taskGroups$ = this.call(this.applicationTaskGroupService.list(1, 100, this.applicationId)
      .pipe(
        switchMap(d => of(d.items)),
      ));
  }

  submit() {
    const form = this.form;

    const observable = (this.applicationTask && this.applicationTask.id)
      ? this.applicationTaskService.update(form.value) : this.applicationTaskService.create(form.value);

    this.call(observable.pipe(
      map(result => {
        if (result && result.id) {
          this.ref.close(result.id);
        }
        return result;
      }),
    )).subscribe();
  }
}
