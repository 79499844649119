import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import 'rxjs-compat/add/operator/map';
import {IModelStateMessages} from '@core/backend/validation';
import {SvrComponent} from '@shared/base/svr.component';
import {SoftwareInfoDTO} from '@core/interfaces/serious-vr/generated-models';
import {SoftwareInfoTaskData} from '@core/interfaces/ecommerce/application-software-info';

@Component({
  selector: 'svr-add-software-info-prompt',
  templateUrl: 'add-software-info.component.html',
  styleUrls: ['add-software-info.component.scss'],
})
export class AddSoftwareInfoPromptComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'APPLICATIONS.SOFTWARE.';

  @Input()
  softwareInfo: SoftwareInfoDTO = null;

  form: FormGroup = null;
  applicationId: number;

  public modelStateMessages: IModelStateMessages = {};

  get softwareVersion() {
    return this.form.get('softwareVersion');
  }

  get url() {
    return this.form.get('url');
  }

  get description() {
    return this.form.get('description');
  }

  protected readonly unsubscribe$ = new Subject<void>();

  constructor(protected ref: NbDialogRef<number>,
              private fb: FormBuilder,
              private softwareInfoService: SoftwareInfoTaskData,
              toastrService: NbToastrService) {
    super(toastrService);
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      id: this.fb.control(undefined),
      applicationId: this.fb.control(undefined),
      softwareVersion: this.fb.control('', [Validators.required,
        Validators.pattern('(0|[1-9]\\d*).(0|[1-9]\\d*).(0|[1-9]\\d*)')]),
      url: this.fb.control(''),
      description: this.fb.control(''),
    });
  }

  initData() {
    if (this.softwareInfo) {
      this.form.setValue({
        ...this.form.value,
        id: this.softwareInfo.id,
        applicationId: this.softwareInfo.applicationId,
        softwareVersion: this.softwareInfo.softwareVersion,
        url: this.softwareInfo.url,
        description: this.softwareInfo.description,
      });
    } else {
      this.form.setValue({
        ...this.form.value,
        applicationId: this.applicationId,
      });
    }
  }

  ngOnInit(): void {
    this.initData();
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    const form = this.form;

    const observable = (this.softwareInfo && this.softwareInfo.id)
      ? this.softwareInfoService.update(form.value) : this.softwareInfoService.create(form.value);

    this.call(observable.map(result => {
      if (result) {
        this.ref.close(result);
      }
    })).subscribe();
  }
}
