import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiBase} from '@core/backend/ecommerce/api/api-base';
import {
  SessionDTO,
  SessionReportDTO,
  IndicatorReportDTO,
  SessionScoreReportCommand,
  IndicatorScoreReportCommand,
  IndicatorOccurrencesCommand,
  IndicatorOccurrencesDTO,
} from '@core/interfaces/serious-vr/generated-models';

@Injectable()
export class SessionsApi extends ApiBase<SessionDTO> {
  readonly apiController: string = 'sessions';

  report(command: SessionScoreReportCommand): Observable<SessionReportDTO> {
    return this.httpService.post(`${this.apiController}/session-score-report`, command);
  }

  reportRadar(command: IndicatorScoreReportCommand): Observable<IndicatorReportDTO> {
    return this.httpService.post(`${this.apiController}/indicator-score-report`, command);
  }

  indicatorOccurrences(command: IndicatorOccurrencesCommand): Observable<IndicatorOccurrencesDTO> {
    return this.httpService.post(`${this.apiController}/indicator-occurrences-report`, command);
  }
}
