import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NbComponentStatus} from '@nebular/theme';

export interface IConfirmationDialogAction<T = any> {
  text: string;
  data?: T;
  status?: NbComponentStatus;
  handler?: () => void;
}

@Component({
  selector: 'svr-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {

  constructor() {
  }

  @Input()
  title: string;

  @Input()
  message: string;

  @Input()
  actions: IConfirmationDialogAction[] = [];

  @Input()
  status: NbComponentStatus;

  @Output()
  action = new EventEmitter<IConfirmationDialogAction>();

  onActionClick(action: IConfirmationDialogAction) {
    if (action.handler) {
      action.handler();
    }
    this.action.emit(action);
  }
}
