import { Injectable } from '@angular/core';
import { ApiBase } from '@core/backend/ecommerce/api/api-base';
import { ApplicationPiGroupDTO } from '@core/interfaces/serious-vr/generated-models';
import { Observable } from 'rxjs';
import { GridData } from '@core/interfaces/common/gridData';

@Injectable()
export class ApplicationsPiGroupApi extends ApiBase<ApplicationPiGroupDTO> {
  readonly apiController: string = 'application-pi-group';

  options(): Observable<GridData<ApplicationPiGroupDTO>> {
    return this.httpService.get(`${this.apiController}/options`);
  }
}
