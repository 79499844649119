import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {SeriousVrUserDTO, ImportedUserDTO} from '@core/interfaces/serious-vr/generated-models';
import {UsersApi} from '@core/backend/ecommerce/api/users.api';
import {Observable} from 'rxjs';
import {SmartTableDataSource} from '@core/backend/common/state/smart-table-data-source';
import {GridData} from '../common/gridData';

export interface IUserData extends IServiceBase<SeriousVrUserDTO, UsersApi> {
  getCurrentUser(): Observable<SeriousVrUserDTO>;
  updateCurrent(user: SeriousVrUserDTO): Observable<SeriousVrUserDTO>;
  changePassword(userId: number, password: string, confirmPassword: string);
  traineeDataSource: SmartTableDataSource;
}

export abstract class UserData extends ServiceBase<SeriousVrUserDTO, UsersApi> implements IUserData {
  protected constructor(api: UsersApi) {
    super(api);
  }

  abstract getCurrentUser(): Observable<SeriousVrUserDTO>;
  abstract updateCurrent(user: SeriousVrUserDTO): Observable<SeriousVrUserDTO>;
  abstract changePassword(userId: number, password: string, confirmPassword: string);
  abstract inviteUser(user: any);
  abstract assignToGroup(userIds: number[], groupTag: string);
  abstract assignToTrainer(traineeIds: number[], trainerId: number);
  abstract assignToApplications(userIds: number[], applicationIds: number[]);
  abstract importCsvTemplate(): Observable<Blob>;
  abstract importCsv(file: File): Observable<ImportedUserDTO[]>;

  get traineeDataSource(): SmartTableDataSource {
    return this.api.traineesDataSource;
  }

  options(): Observable<GridData<SeriousVrUserDTO>> {
    return this.api.options();
  }

  trainers(): Observable<GridData<SeriousVrUserDTO>> {
    return this.api.trainers();
  }
}
