import {NbMenuItem} from '@nebular/theme';
import {Observable} from 'rxjs';
import {IMenuItemData} from '@core/types';
import {Injectable} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '@core/auth.service';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(private authService: AuthService) {
  }

  processMenuItem(menuItem: NbMenuItem, takeUntil$: Observable<any>) {
    if (menuItem.children !== undefined && menuItem.children !== null) {
      menuItem.children.forEach(item => this.processMenuItem(item, takeUntil$));
    }

    if (menuItem.data !== undefined) {
        const data: IMenuItemData = menuItem.data;
        if (data.claimRequirement) {
          this.authService.selectHasAccess(data.claimRequirement)
            .pipe(takeUntil(takeUntil$))
            .subscribe(hasAccess => {
              menuItem.hidden = !hasAccess;
            });
        }
      }
    }
}
