import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {TierDTO} from '@core/interfaces/serious-vr/generated-models';
import {TierApi} from '@core/backend/ecommerce/api/tier.api';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';

export interface ITierData extends IServiceBase<TierDTO, TierApi> {
  options(): Observable<GridData<TierDTO>>;
}

export abstract class TierData
  extends ServiceBase<TierDTO, TierApi>
  implements ITierData {
  protected constructor(api: TierApi) {
    super(api);
  }

  options(): Observable<GridData<TierDTO>> {
    return this.api.options();
  }
}
