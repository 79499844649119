import {ClaimRequirement} from '@core/claim-requirement';
import {Claim} from '@core/claim';

export const userManagementViewClaimRequirement = ClaimRequirement.byAll(Claim.UserManagementView);
export const userManagementEditClaimRequirement = ClaimRequirement.byAll(Claim.UserManagementEdit);
export const userManagementRemoveClaimRequirement = ClaimRequirement.byAll(Claim.UserManagementRemove);

export const anyUserManagementClaimRequirement = ClaimRequirement.byAny(
  userManagementViewClaimRequirement,
  userManagementEditClaimRequirement,
  userManagementRemoveClaimRequirement,
);
