import {
  APP_INITIALIZER,
} from '@angular/core';
import {AppConfig} from '@core/app.config';

const loadConfig = (config: AppConfig) => () => config.load();

export const CORE_PROVIDERS = [
  AppConfig,
  {
    provide: APP_INITIALIZER,
    deps: [ AppConfig ],
    useFactory: loadConfig,
    multi: true,
  },
];
