import {Component, Input, OnInit} from '@angular/core';

export enum ChartColors {
  default,
  success,
  inverse,
  neutral,
}

@Component({
  selector: 'svr-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent implements OnInit {

  @Input() min: number = 0;
  @Input() max: number = 0;
  @Input() value: number = 0;
  @Input() minValue: number = null;
  @Input() small: boolean = false;
  @Input() bottomLabel: string = '';
  @Input() chartColor: ChartColors = ChartColors.default;
  @Input() title: string;

  public canvasWidth = 250;
  public centralLabel = '';
  public options = {};

  get achievedPercentage() {
    return this.calculatePercentage(this.value, this.max);
  }

  get arcColorPercentage() {
    return this.calculatePercentage(this.minValue, this.max);
  }

  calculatePercentage(val: number, max: number) {
    let percentage = val / max * 100;
    if (percentage <= 0) {
      percentage = 0.001;
    } else if (percentage >= 100) {
      percentage = 99.999;
    }
    return percentage;
  }

  ngOnInit() {
    let colors;

    switch (this.chartColor) {
      case ChartColors.default: {
        colors = ['#db191e', '#597724'];
        break;
      }
      case ChartColors.success: {
        colors = ['#597724', '#597724'];
        break;
      }
      case ChartColors.inverse: {
        colors = ['#597724', '#db191e'];
        break;
      }
      case ChartColors.neutral: {
        colors = ['#c8c8c8', '#c8c8c8'];
        break;
      }
    }

    const rangeLabel = ['0', this.max.toString()];

    if (this.small) {
      this.canvasWidth = 150;
    }

    this.options = {
      hasNeedle: true,
      needleValue: this.achievedPercentage,
      needleColor: '#848484',
      needleUpdateSpeed: 500,
      arcColors: colors,
      arcDelimiters: [this.arcColorPercentage],
      rangeLabel: rangeLabel,
      needleStartValue: 0,
    };
  }
}
