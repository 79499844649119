import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
} from './utils';

import { CommonBackendModule } from './backend/common/common-backend.module';
import { EcommerceBackendModule } from './backend/ecommerce/ecommerce-backend.module';
import { UserStore } from './stores/user.store';
import { SettingsService } from './backend/common/services/settings.service';
import { InitUserService } from '@theme/services/init-user.service';
import {CORE_PROVIDERS} from '@core/core.providers';
import {NbSecurityModule} from '@nebular/security';
import {authOptions} from '../@auth/auth.settings';
import {AuthModule} from '../@auth/auth.module';
import {UsersService} from '@core/backend/ecommerce/services/users.service';
import { FormulaService } from './backend/common/services/formula.service';

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup(authOptions),
    ],
    forms: {
      login: {
      },
      register: {
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  ...AuthModule.forRoot().providers,
  ...CommonBackendModule.forRoot().providers,
  ...EcommerceBackendModule.forRoot().providers,

  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        ...CORE_PROVIDERS,
        UserStore,
        UsersService,
        InitUserService,
        SettingsService,
        FormulaService,
      ],
    };
  }
}
