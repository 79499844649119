import { Component } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { SvrComponent } from '../../base/svr.component';
import { UserData } from '@core/interfaces/ecommerce/user';

@Component({
  selector: 'svr-import-csv-prompt',
  templateUrl: 'import-csv-dialog.component.html',
  styleUrls: ['import-csv-dialog.component.scss'],
})
export class ImportCsvDialogComponent extends SvrComponent {
  readonly translationPrefix = 'USERS.';
  file: File;

  constructor(protected ref: NbDialogRef<number>,
    private userService: UserData,
    toastrService: NbToastrService) {
    super(toastrService);
  }

  fileChange(event) {
      if (event.target.files.length === 0) return;
      this.file = event.target.files[0];
  }

  uploadFile() {
      this.ref.close(this.file);
  }

  downloadTemplate() {
      this.userService.importCsvTemplate()
        .pipe()
        .subscribe(blob => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = 'template.csv';
            a.click();
            URL.revokeObjectURL(objectUrl);
        });
  }
}

