import {Injectable} from '@angular/core';
import {ApiBase} from '@core/backend/ecommerce/api/api-base';
import {OrganizationDTO, RecoverOrganizationCommand} from '@core/interfaces/serious-vr/generated-models';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';

@Injectable()
export class OrganizationsApi extends ApiBase<OrganizationDTO> {
  readonly apiController: string = 'organizations';

  options(): Observable<GridData<OrganizationDTO>> {
    return this.httpService.get(`${this.apiController}/options`);
  }

  current(): Observable<OrganizationDTO> {
    return this.httpService.get(`${this.apiController}/current`);
  }

  recover(command: RecoverOrganizationCommand): Observable<OrganizationDTO> {
    return this.httpService.post(`${this.apiController}/recover-organization`, command);
  }
}
