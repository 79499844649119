import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {ApplicationsSoftwareInfoApi} from '@core/backend/ecommerce/api/applications-software-info.api';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';
import {HttpParams} from '@angular/common/http';
import {SoftwareInfoDTO} from '@core/interfaces/serious-vr/generated-models';

export interface ISoftwareInfoData extends IServiceBase<SoftwareInfoDTO, ApplicationsSoftwareInfoApi> {
}

export abstract class SoftwareInfoTaskData extends ServiceBase<SoftwareInfoDTO, ApplicationsSoftwareInfoApi>
implements ISoftwareInfoData {
  protected constructor(api: ApplicationsSoftwareInfoApi) {
    super(api);
  }

  list(pageNumber: number = 1, pageSize: number = 10, applicationId: number = null):
    Observable<GridData<SoftwareInfoDTO>> {
    const params = new HttpParams().set('FilterByApplicationId', `${applicationId}`);
    return this.api.list(pageNumber, pageSize, params);
  }
}
