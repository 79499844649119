import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SvrComponent } from '../../base/svr.component';
import { UserData } from '@core/interfaces/ecommerce/user';

@Component({
  selector: 'svr-assign-users-to-group-prompt',
  templateUrl: 'assign-users-to-group-dialog.component.html',
  styleUrls: ['assign-users-to-group-dialog.component.scss'],
})
export class AssignUsersToGroupDialogComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'USERS.';

  form: FormGroup = null;
  userIds: number[];

  constructor(protected ref: NbDialogRef<number>,
    private fb: FormBuilder,
    private userService: UserData,
    toastrService: NbToastrService) {
    super(toastrService);
  }

  submit() {
    this.call(this.userService.assignToGroup(this.userIds, this.form.value.groupName)).subscribe(_ => {
      this.ref.close(true);
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      groupName: this.fb.control('', [Validators.required]),
    });
  }
}

