import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class FormulaApi {
  private readonly apiController: string = 'formula';

  constructor(private api: HttpService) {}

  compileFormula(formula: any): Observable<any> {
    return this.api.post(`${this.apiController}/compile/formula`, formula);
  }

  compileApplication(formula: any): Observable<any> {
    return this.api.post(`${this.apiController}/compile/application`, formula);
  }

  testFormula(formula: any): Observable<any> {
    return this.api.post(`${this.apiController}/test/formula`, formula);
  }

  testApplication(formula: any): Observable<any> {
    return this.api.post(`${this.apiController}/test/application`, formula);
  }
}
