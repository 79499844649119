import {ClaimRequirementDirective} from './directives/claim-requirement.directive';
import {NgModule} from '@angular/core';

@NgModule({
  declarations: [
    ClaimRequirementDirective,
  ],
  exports: [
    ClaimRequirementDirective,
  ],
})
export class AuthDirectivesModule {
}

