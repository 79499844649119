import { TranslateLoader } from '@ngx-translate/core';
import {
  from,
  Observable,
} from 'rxjs';
import { map } from 'rxjs/operators';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`./i18n/${lang}`)).pipe(map(l => l.default));
  }
}
