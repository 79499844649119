import {ConfigurationType} from '@core/interfaces/serious-vr/enums';


export function getTranslationKey(type: ConfigurationType) {
  const prefix = 'CONFIGURATION_TYPES.';

  switch (type) {
    case ConfigurationType.TRAINING:
      return `${prefix}TRAINING`;
    case ConfigurationType.INSTRUCTION:
      return `${prefix}INSTRUCTION`;
    case ConfigurationType.ASSESSMENT:
      return `${prefix}ASSESSMENT`;
    default:
      return `${prefix}UNKNOWN`;
  }
}
