import {Component, Input} from '@angular/core';
import {ItemInfoDTO, SessionPiDTO} from '@core/interfaces/serious-vr/generated-models';
import {ChartColors} from '@shared/gauge-chart/gauge-chart.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'svr-session-configuration-pi-card',
  templateUrl: './session-configuration-pi-card.component.html',
  styleUrls: ['./session-configuration-pi-card.component.scss'],
})
export class SessionConfigurationPiCardComponent {

  @Input()
  configurationPi: SessionPiDTO;

  colors = ChartColors;

  constructor(private translate: TranslateService) {
  }

  translatePassedFailed(value: string) {
    return this.translate.instant(value);
  }

  isErrorIndicator(piDto: ItemInfoDTO) {
    return piDto && piDto.key.includes('ApplicationErrorIndicator');
  }

  getGaugeTitle(text: string, value: number) {
    if (value > 0) {
      return `${this.translate.instant(text)}: ${value}`;
    }
    return '';
  }
}
