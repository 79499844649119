import {SessionsApi} from '@core/backend/ecommerce/api/sessions.api';
import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {Observable} from 'rxjs';

import {
  SessionDTO,
  SessionReportDTO,
  IndicatorReportDTO,
  SessionScoreReportCommand,
  IndicatorScoreReportCommand,
  IndicatorOccurrencesCommand,
  IndicatorOccurrencesDTO,
} from '@core/interfaces/serious-vr/generated-models';

export interface ApplicationPi {
  name: string;
  description: string;
}

export interface ISessionData extends IServiceBase<SessionDTO, SessionsApi> {
}

export abstract class SessionData extends ServiceBase<SessionDTO, SessionsApi> implements ISessionData {
  protected constructor(api: SessionsApi) {
    super(api);
  }

  report(command: SessionScoreReportCommand): Observable<SessionReportDTO> {
    return this.api.report(command);
  }

  reportRadar(command: IndicatorScoreReportCommand): Observable<IndicatorReportDTO> {
    return this.api.reportRadar(command);
  }

  indicatorOccurrences(command: IndicatorOccurrencesCommand): Observable<IndicatorOccurrencesDTO> {
    return this.api.indicatorOccurrences(command);
  }
}
