import {ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {Observable} from 'rxjs';
import {PerformanceIndicatorApiBase} from '@core/backend/ecommerce/api/performance-indicators/applications-pi-base.api';
import {GridData} from '@core/interfaces/common/gridData';
import {HttpParams} from '@angular/common/http';
import {SoftwareInfoDTO} from '@core/interfaces/serious-vr/generated-models';
import {PerformanceIndicatorType} from '@core/interfaces/serious-vr/enums';

export interface PiImplementation {
  key: string;
  type: PerformanceIndicatorType;
}

export interface ApplicationPi {
  id: number;
  applicationId: number;
  name: string;
  description: string;
  implementationKey: string;
  type: PerformanceIndicatorType;
  maxVersion: SoftwareInfoDTO;
  minVersion: SoftwareInfoDTO;
  maxScore?: number;
  formulaKey: string;
  createdDate?: Date;
}

export interface ICreateIndicator<TScore extends IScore, TSettings extends IPiSettingsDto<TScore>> {
  applicationId: number;
  name: string;
  description: string;
  implementationKey: string;
  minVersionId: number;
  maxVersionId: number;
  formulaKey: string;
  settings: TSettings;
}

export interface IUpdateIndicator<TScore extends IScore, TSettings extends IPiSettingsDto<TScore>>
  extends ICreateIndicator<TScore, TSettings> {
  id: number;
}

export interface IPiSettingsDto<TScore> {
  scores: TScore[];
}

export interface IScore {
  score: number;
}

export abstract class PerformanceIndicatorBaseData<
  TScore extends IScore,
  TSettingsDto extends IPiSettingsDto<TScore>,
  TApi extends PerformanceIndicatorApiBase<TSettingsDto>>
  extends ServiceBase<ApplicationPi, TApi> {

  protected constructor(api: TApi) {
    super(api);
  }

  implementations(): Observable<Array<PiImplementation>> {
    return this.api.getImplementations();
  }

  getSettings(id: number): Observable<TSettingsDto> {
    return this.api.getSettings(id);
  }

  setSettings(entity: any) {
    return this.api.setSettings(entity);
  }

  list(pageNumber: number = 1, pageSize: number = 10, applicationId: number = null):
  Observable<GridData<ApplicationPi>> {
    const params = new HttpParams().set('FilterByApplicationId', `${applicationId}`);
    return this.api.list(pageNumber, pageSize, params);
  }
}

export abstract class PerformanceIndicatorData
  extends PerformanceIndicatorBaseData<IScore, IPiSettingsDto<IScore>,
    PerformanceIndicatorApiBase<IPiSettingsDto<IScore>>> {
}
