import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'svr-passed-failed',
  templateUrl: './passed-failed.component.html',
  styleUrls: ['./passed-failed.component.scss'],
})
export class PassedFailedComponent implements OnInit {

  @Input() failed: number;
  @Input() passed: number;
  @Input() failedTitle: string = this.translate.instant('failed');
  @Input() passedTitle: string = this.translate.instant('passed');

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
  }
}
