import {Component, Input, OnInit, Optional} from '@angular/core';
import {SvrComponent} from '@shared/base/svr.component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {ConfigurationService} from '@core/backend/ecommerce/services/configuration.service';
import {switchMap} from 'rxjs/operators';
import {ApplicationSoftwareInfoService} from '@core/backend/ecommerce/services/application-software-info.service';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationDTO, SoftwareInfoDTO} from '@core/interfaces/serious-vr/generated-models';
import {ConfigurationType} from '@core/interfaces/serious-vr/enums';
import * as fromConfigurationType from '@core/interfaces/ecommerce/configurations/configuration-type';
import date from '@core/utils/date';
import { FormulaData } from '@core/interfaces/common/formula';
import { FormulaCompileResultDTO } from '@core/interfaces/common/formula';

@Component({
  selector: 'svr-add-configuration',
  templateUrl: './add-configuration.component.html',
  styleUrls: ['./add-configuration.component.scss'],
})
export class AddConfigurationComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'APPLICATIONS.CONFIGURATION.';

  @Input() configuration: ConfigurationDTO = null;
  @Input() applicationId: number;

  form: FormGroup = null;
  softwareInfo$: Observable<SoftwareInfoDTO[]>;

  configurationTypes = ConfigurationType;


  selectedMinVersion: number;
  selectedMaxVersion: number;
  compileResult: FormulaCompileResultDTO;

  private editMode = false;

  constructor(toastrService: NbToastrService,
              private translate: TranslateService,
              @Optional() protected ref: NbDialogRef<number>,
              private configurationService: ConfigurationService,
              private applicationSoftwareVersion: ApplicationSoftwareInfoService,
              private fb: FormBuilder,
              private formulaService: FormulaData,
  ) {
    super(toastrService);
  }

  ngOnInit() {
    this.softwareInfo$ = this.call(
      this.applicationSoftwareVersion
        .list(1, 100, this.configuration ? this.configuration.applicationId : this.applicationId)
        .pipe(
          switchMap(d => of(d.items)),
        ));

    this.editMode = (this.configuration && this.configuration.id > 0);
    this.initForm();
  }

  initForm() {
    if (this.configuration) {
      this.selectedMaxVersion = this.configuration.maxVersion ? this.configuration.maxVersion.id : undefined;
      this.selectedMinVersion = this.configuration.minVersion ? this.configuration.minVersion.id : undefined;

      this.form = this.fb.group({
        id: this.initComponent(this.configuration.id),
        applicationId: this.initComponent(this.configuration.applicationId),
        key: this.initComponent(this.configuration.key),
        name: this.initComponent(this.configuration.name),
        description: this.initComponent(this.configuration.description),
        minVersionId: this.initComponent(this.selectedMinVersion, undefined),
        maxVersionId: this.initComponent(this.selectedMaxVersion, undefined),
        maxScore:  this.initComponent(this.configuration.maxScore),
        scoreToPass: this.initComponent(this.configuration.scoreToPass),
        type: this.initComponent(this.configuration.type),
        totalScoreFormula: this.initComponent(this.configuration.totalScoreFormula),
      });
    } else {
      this.form = this.fb.group({
        id: this.initComponent(null),
        applicationId: this.initComponent(this.applicationId),
        key: this.initComponent(null),
        name: this.initComponent(null),
        description: this.initComponent(null),
        minVersionId: this.initComponent(null),
        maxVersionId: this.initComponent(null),
        maxScore: this.initComponent(0),
        scoreToPass: this.initComponent(0),
        type: this.initComponent(null),
        totalScoreFormula: this.initComponent(null),
      });
    }
  }

  submit() {
    // Check if formula is valid
    this.compileResult = null;

    const compileObservable = !!this.form.value.applicationId ?
      this.formulaService.compileApplication(this.form.value.totalScoreFormula, this.form.value.applicationId) :
      this.formulaService.compileFormula(this.form.value.totalScoreFormula);

    this.call(compileObservable)
      .subscribe(compileRes => {
        this.compileResult = compileRes;

        // Only save configuration if formula compiles successfully
        if (compileRes && compileRes.success === true) {
          const form = this.form;

          const observable = this.editMode
            ? this.configurationService.update(form.value) : this.configurationService.create(form.value);

          this.call(observable.map(result => {
            if (result) {
              const translationKey = this.translationPrefix + (this.editMode ? 'UPDATED' : 'CREATED');
              this.toastrService.success('', this.translate.instant(translationKey));

              if (this.ref) {
                this.ref.close(result);
              }
            }
          })).subscribe();

        } else {
          this.toastrService.danger('', this.translate.instant(this.translationPrefix + 'FORMULA_INVALID'));
        }
      });
  }

  formatCreatedOn(createdOn: Date) {
    return date.formatDate(createdOn);
  }

  typeTranslation(value: ConfigurationType) {
    return this.translate.instant(fromConfigurationType.getTranslationKey(value));
  }

  private initComponent<T>(data: T, whenNull: string | number = ''): FormControl {
    return this.fb.control(data ? data : whenNull);
  }
}
