import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {ConfigurationsPiApi} from '@core/backend/ecommerce/api/configurations/configurations-pi.api';
import {ConfigurationPiDTO} from '@core/interfaces/serious-vr/generated-models';

export interface IConfigurationPiData extends IServiceBase<ConfigurationPiDTO, ConfigurationsPiApi> {
}

export abstract class ConfigurationPiData extends
  ServiceBase<ConfigurationPiDTO, ConfigurationsPiApi> implements IConfigurationPiData  {
  protected constructor(api: ConfigurationsPiApi) {
    super(api);
  }
}
