import {IModelStateMessages, ModelStateError, ValidationError} from '@core/backend/validation';
import {Observable, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';

export abstract class SvrComponent {

  protected readonly unsubscribe$ = new Subject<void>();
  protected modelStateMessages: IModelStateMessages = {};

  protected constructor(protected toastrService: NbToastrService) {
  }

  protected call<T>(call: Observable<T>): Observable<T> {
    return call.pipe(
      takeUntil(this.unsubscribe$),
      catchError(error => {
        this.handleError(error);
        return of(error);
      }),
    );
  }

  handleError(error: any) {
    if (error instanceof ModelStateError) {
      this.modelStateMessages = error.modelStateMessages;
    } else if (error instanceof ValidationError) {
      this.toastrService.danger('', error.data);
    }
  }
}
