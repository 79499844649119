import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {ConfigurationsApi} from '@core/backend/ecommerce/api/configurations/configurations.api';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';
import {HttpParams} from '@angular/common/http';
import {ConfigurationDTO} from '@core/interfaces/serious-vr/generated-models';



export interface IConfigurationData extends IServiceBase<ConfigurationDTO, ConfigurationsApi> {
}

export abstract class ConfigurationData extends ServiceBase<ConfigurationDTO, ConfigurationsApi>
  implements IConfigurationData  {
  protected constructor(api: ConfigurationsApi) {
    super(api);
  }

  list(pageNumber: number = 1, pageSize: number = 10, applicationId: number = null):
  Observable<GridData<ConfigurationDTO>> {
    const params = new HttpParams().set('FilterByApplicationId', `${applicationId}`);
    return this.api.list(pageNumber, pageSize, params);
  }
}
