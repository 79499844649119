import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SvrComponent} from '@shared/base/svr.component';
import {TranslateService} from '@ngx-translate/core';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {UsersService} from '@core/backend/ecommerce/services/users.service';

@Component({
  selector: 'svr-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'CHANGE_PASSWORD';


  @Input() UserId: number;

  form: FormGroup;

  constructor(private fb: FormBuilder,
              toastrService: NbToastrService,
              private translate: TranslateService,
              @Optional() protected ref: NbDialogRef<number>,
              private usersService: UsersService) {
    super(toastrService);
  }

  get password() {
    return this.form.get('password');
  }

  get confirmPassword() {
    return this.form.get('confirmPassword');
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      userId: this.fb.control(this.UserId),
      password: this.fb.control('', [Validators.minLength(6), Validators.maxLength(50)]),
      confirmPassword: this.fb.control('', [Validators.minLength(6), Validators.maxLength(50)]),
    });
  }

  save() {
    const observable = this.usersService.changePassword(this.UserId, this.password.value, this.confirmPassword.value);
    this.call(observable.map(result => {
      if (result) {
        this.toastrService.success('', this.translate.instant('UPDATED'));

        if (this.ref) {
          this.ref.close(result);
        }
      }
    })).subscribe();
  }
}
