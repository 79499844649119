import {ApiBase} from '@core/backend/ecommerce/api/api-base';
import {SeriousVrUserDTO, ImportedUserDTO} from '@core/interfaces/serious-vr/generated-models';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {GridData} from '@core/interfaces/common/gridData';
import {SmartTableDataSource} from '@core/backend/common/state/smart-table-data-source';

@Injectable()
export class UsersApi extends ApiBase<SeriousVrUserDTO> {
  readonly apiController: string = 'users';

  options(): Observable<GridData<SeriousVrUserDTO>> {
    return this.httpService.get(`${this.apiController}/options`);
  }

  trainers(): Observable<GridData<SeriousVrUserDTO>> {
    return this.httpService.get(`${this.apiController}/trainers`);
  }

  list(pageNumber: number = 1, pageSize: number = 10,  parameters: HttpParams = new HttpParams()):
    Observable<GridData<SeriousVrUserDTO>> {
    parameters
      .set('pageNumber', `${pageNumber}`)
      .set('pageSize', `${pageSize}`);

    return this.httpService.get(this.apiController, { parameters })
      .pipe(map(data => data.map(item => {
        // TODO picture not working in this version
        // const picture = `${this.httpService.apiUrl}/${this.apiController}/${item.id}/photo`;
        // return { ...item, picture };
        return item;
      })));
  }

  get traineesDataSource(): SmartTableDataSource {
    return this.httpService.getServerDataSource(`${this.httpService.apiUrl}/${this.apiController}/trainees`);
  }

  get organizationDataSource(): SmartTableDataSource {
    return this.httpService.getServerDataSource(`${this.httpService.apiUrl}/${this.apiController}/organizations`);
  }

  // TODO make typed
  getCurrent(): Observable<any> {
    return this.httpService.get(`${this.apiController}/current`)
      .pipe(map(data => {
        const picture = `${this.httpService.apiUrl}/${this.apiController}/${data.id}/photo`;
        return { ...data, picture };
      }));
  }

  get(id: number): Observable<any> {
    return this.httpService.get(`${this.apiController}/${id}`)
      .pipe(map(data => {
        const picture = `${this.httpService.apiUrl}/${this.apiController}/${data.id}/photo`;
        return {...data, picture};
      }));
  }

  // TODO make typed
  add(item: any): Observable<SeriousVrUserDTO> {
    return this.httpService.post(`${this.apiController}/sign-up`, item);
  }

  changePassword(userId: number, password: string, confirmPassword: string) {
    return this.httpService.post(`${this.apiController}/reset-pass`, {userId, password, confirmPassword});
  }

  // TODO make typed
  updateCurrent(item: any): Observable<any> {
    return this.httpService.put(`${this.apiController}/current`, item);
  }

  // TODO make typed
  inviteUser(item: any): Observable<any> {
    return this.httpService.post(`${this.apiController}/invite`, item);
  }

  assignToGroup(userIds: number[], groupTag: string) {
    return this.httpService.post(`${this.apiController}/assign-to-group`, {
      userIds: userIds,
      groupTag: groupTag,
    });
  }

  assignToTrainer(traineeIds: number[], trainerId: number) {
    return this.httpService.post(`${this.apiController}/assign-trainer-to-trainees`, {
      traineeIds: traineeIds,
      trainerId: trainerId,
    });
  }

  assignToApplications(userIds: number[], applicationIds: number[]) {
    return this.httpService.post(`${this.apiController}/assign-applications-to-users`, {
      usersIds: userIds,
      applicationIds: applicationIds,
    });
  }

  importCsvTemplate(): Observable<Blob> {
    return this.httpService.get(`${this.apiController}/import-csv-template`, {
      responseType: 'blob',
    });
  }

  importCsv(file: File): Observable<ImportedUserDTO[]> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const headers = new Headers();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.httpService.post(`${this.apiController}/import-csv`, formData, {
      headers: headers,
    });
  }
}
