import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';
import {ApplicationTaskDTO} from '@core/interfaces/serious-vr/generated-models';
import {ApplicationErrorData} from '@core/interfaces/ecommerce/application-error';

@Injectable()
export class ApplicationErrorsService extends ApplicationErrorData {
  getErrorsForApplication(applicationId: number): Observable<GridData<ApplicationTaskDTO>> {
    const params = new HttpParams()
      .set('FilterByApplicationId', `${applicationId}`);
    return this.api.list(1, 999, params);
  }
}

