import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'enumToArray'})
export class EnumToArrayPipe implements PipeTransform {
  transform(value): Object {
    return Object.keys(value).filter(e => !isNaN(+e)).map(o => ({id: +o, name: value[o]}));
  }
}

export function EnumToSelectArray(value: any, translatePrefix: string, translate: TranslateService) {
  return Object.keys(value).filter(e => !isNaN(+e))
    .map(o => ({value: +o, title: translate.instant(translatePrefix + value[o])}));
}
