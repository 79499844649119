import {ApiBase} from '@core/backend/ecommerce/api/api-base';
import {Observable} from 'rxjs';
import {PiImplementation, ApplicationPi} from '@core/interfaces/ecommerce/pis/application-pi-base';
import {Injectable} from '@angular/core';

@Injectable()
export class PerformanceIndicatorApiBase<TSettingDto>
  extends ApiBase<ApplicationPi> {
  apiController = 'application-pi';

  getImplementations(): Observable<Array<PiImplementation>> {
    return this.httpService.get(`${this.apiController}/implementations`);
  }

  getSettings(id: number): Observable<TSettingDto> {
    return this.httpService.get(`${this.apiController}/${id}/settings`);
  }

  setSettings(entity: any) {
    return this.httpService.put(`${this.apiController}/settings`, entity);
  }
}
