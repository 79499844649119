import {ErrorCode} from './errorCodes';

export class ValidationError<T> extends Error {
  constructor(message: string, public errorCode: ErrorCode, public data?: T) {
    super(message);
  }
}

export interface IModelStateMessages {
  [field: string]: string[];
}

export class ModelStateError extends Error {
  constructor(public modelStateMessages: IModelStateMessages) {
    super();
  }
}
