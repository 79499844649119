import { Injectable } from '@angular/core';
import 'rxjs/add/operator/catch';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  throwError,
} from 'rxjs';
import {IConfig} from '@core/interfaces/config';

@Injectable()
export class AppConfig {

  loaded$ = new BehaviorSubject(false);

  private config: IConfig = null;
  private env: Object = null;
  private noCacheHeaders = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
  };
  private _loadPromise: Promise<any>;

  constructor(private http: HttpClient) {
  }

  /**
   * Use to get the user found in the second file (config file)
   */
  public getConfigKey<T>(key: any): T {
    return <T>key.split('.').reduce((acc, curr) => acc[ curr ], this.config);
  }

  public getConfig(): IConfig {
    return this.config;
  }

  /**
   * Use to get the user found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[ key ];
  }

  public get loaded(): boolean {
    return this.loaded$.value;
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load(): Promise<any> {
    if (this._loadPromise) {
      return this._loadPromise;
    }
    this._loadPromise = new Promise((resolve, reject) => {
      this.config = null;
      this.loaded$.next(false);
      this.http
        .get('assets/env.json', {
          headers: this.noCacheHeaders,
        })
        .catch((error: any): any => {
          // tslint:disable
          console.log('Configuration file "env.json" could not be read');
          // tslint:enable
          console.error(error);
          resolve(true);
          return throwError(error.json().error || 'Server error');
        })
        .subscribe((envResponse: { env: string }) => {
          this.env = envResponse;
          let request: any = null;

          switch (envResponse.env) {
            case 'local':
            case 'development':
            case 'production':
            case 'testing':
            case 'acceptance': {
              request = this.http.get('assets/config/config.' + envResponse.env + '.json', {
                headers: this.noCacheHeaders,
              });
              break;
            }
            case 'default': {
              console.error('env.json file is invalid. no configuration file found');
              break;
            }
          }

          if (request) {
            request
              .catch((error: any) => {
                console.error('Error reading ' + envResponse.env + ' configuration file');
                resolve(error);
                return throwError(error.json().error || 'Server error');
              })
              .subscribe(responseData => {
                this.config = responseData;
                resolve(true);
                this.loaded$.next(true);
              });
          } else {
            console.error('Env config file "env.json" is not valid');
            resolve(true);
          }
        });
    }).then(r => {
      this._loadPromise = undefined;
      return r;
    });
    return this._loadPromise;
  }
}
