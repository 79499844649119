import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
    constructor(private translate: TranslateService) {
    }

    transform(value: string): string {
        if (!value) return '-';

        const hr = this.translate.instant(`SESSION.TIME.HR`);
        const min = this.translate.instant(`SESSION.TIME.MIN`);
        const sec = this.translate.instant(`SESSION.TIME.SEC`);
        const duration = moment.duration(value);
        const secs = Math.round(Number(`${duration.seconds()}.${duration.milliseconds()}`));
        const showHr = duration.hours() > 0;
        const showMin = duration.minutes() > 0;
        const showSec = secs > 0;
        const result = [];

        if (showHr) {
            result.push(`${duration.hours()} ${hr}`);
        }

        if (showMin) {
            result.push(`${duration.minutes()} ${min}`);
        }

        if (showSec || result.length === 0) {
            result.push(`${secs} ${sec}`);
        }

        return result.join(', ');
    }
}
