import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganizationData } from '@core/interfaces/ecommerce/organization';
import { OrganizationRoleNameData } from '@core/interfaces/ecommerce/organization-role';
import { SvrComponent } from '../../base/svr.component';
import { SeriousVrRoleType } from '@core/interfaces/serious-vr/enums';
import { TierData } from '@core/interfaces/ecommerce/tier';
import { TierDTO } from '@core/interfaces/serious-vr/generated-models';

@Component({
  selector: 'svr-add-organization-dialog-prompt',
  templateUrl: 'add-organization-dialog.component.html',
  styleUrls: ['add-organization-dialog.component.scss'],
})
export class AddOrganizationDialogComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'ORGANIZATIONS.';

  form: FormGroup = null;
  tiers: TierDTO[] = null;

  constructor(protected ref: NbDialogRef<number>,
    private fb: FormBuilder,
    private organizationsService: OrganizationData,
    private organizationRoleService: OrganizationRoleNameData,
    private tierService: TierData,
    toastrService: NbToastrService) {
    super(toastrService);
    this.form = this.fb.group({
      name: this.fb.control('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      tierId: this.fb.control(undefined, [Validators.required]),
      roleNameManager: this.fb.control('', undefined),
      roleNameManagerPlural: this.fb.control('', undefined),
      roleNameInstructor: this.fb.control('', undefined),
      roleNameInstructorPlural: this.fb.control('', undefined),
      roleNameUser: this.fb.control('', undefined),
      roleNameUserPlural: this.fb.control('', undefined),
    });
  }

  submitRoleName(organizationId: number, roleType: SeriousVrRoleType, value: string) {
    if (value && value.length > 0) {
      this.call(this.organizationRoleService.create({
        organizationId: organizationId,
        roleType: roleType,
        roleName: value,
      })).subscribe();
    }
  }

  submit() {
    this.call(this.organizationsService.create(this.form.value)
      .map(result => {
        if (result && result.id) {
          this.submitRoleName(result.id, SeriousVrRoleType.USER, this.form.value.roleNameUser);
          this.submitRoleName(result.id, SeriousVrRoleType.INSTRUCTOR, this.form.value.roleNameInstructor);
          this.submitRoleName(result.id, SeriousVrRoleType.MANAGER, this.form.value.roleNameManager);
          this.ref.close(result.id);
        }
        return result;
      })).subscribe();
  }

  ngOnInit() {
    this.loadTiers();
  }

  loadTiers() {
    this.tierService.list()
      .pipe()
      .subscribe(response => {
        this.tiers = response.items;
      });
  }
}
