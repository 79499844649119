import {NgModule} from '@angular/core';
import {NgxValidationMessageComponent} from './validation-message/validation-message.component';
import {AddApplicationPromptComponent} from './applications/add-application/add-application.component';
import {
    NbAccordionModule,
    NbBadgeModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbInputModule, NbListModule,
    NbSelectModule,
    NbStepperModule,
    NbWindowModule,
    NbProgressBarModule,
    NbDatepickerModule,
} from '@nebular/theme';
import {SharedModule} from '../@shared/shared.module';
import {AddSoftwareInfoPromptComponent} from './applications/add-software-info/add-software-info.component';
import {EditApplicationTaskComponent} from '@shared/applications/edit-application-task/edit-application-task.component';
import {EditApplicationPiComponent} from '@shared/applications/edit-application-pi/edit-application-pi.component';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {TimeScorePromptComponent} from '@shared/applications/scores/time-score/time-score-prompt.component';
import {TaskScorePromptComponent} from '@shared/applications/scores/task-score/task-score-prompt.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {AddConfigurationComponent} from '@shared/applications/add-configuration/add-configuration.component';
import {AddConfigurationPiComponent} from '@shared/configurations/add-configuration-pi/add-configuration-pi.component';
import {GaugeChartComponent} from './gauge-chart/gauge-chart.component';
import {GaugeChartModule} from 'angular-gauge-chart';
import {PassedFailedComponent} from './passed-failed/passed-failed.component';
import {TableComponent} from '@shared/table/table.component';
import {ChangePasswordComponent} from '@shared/change-password/change-password.component';
import {SessionConfigurationPiCardComponent} from '@shared/sessions/session-configuration-pi/session-configuration-pi-card.component';
import {SessionPiCardComponent} from '@shared/sessions/session-pi/session-pi-card.component';
import {SessionComponent} from '@shared/sessions/session/session.component';
import {AddOrganizationDialogComponent} from '@components/organizations/add-organization/add-organization-dialog.component';
import {InviteUserDialogComponent} from '@components/user/invite-user/invite-user-dialog.component';
import {DurationPipe} from '@components/pipes/duration.pipe.ts';
import { ChartsModule } from 'ng2-charts';
import { SessionReportComponent } from '@components/sessions/session/session-report.component';
import {AssignUsersToGroupDialogComponent} from '@components/user/assign-users-to-group/assign-users-to-group-dialog.component';
import {AssignUsersToApplicationDialogComponent} from '@components/user/assign-users-to-application/assign-users-to-application-dialog.component';
import {AssignTraineesToTrainerDialogComponent} from '@components/user/assign-trainees-to-trainer/assign-trainees-to-trainer-dialog.component';
import {ImportCsvDialogComponent} from '@components/user/import-csv/import-csv-dialog.component';
import {ImportResultDialogComponent} from '@components/user/import-result/import-result-dialog.component';
import { RouterModule } from '@angular/router';

const modules = [
  NbCardModule,
  NbInputModule,
  NbButtonModule,
  NbSelectModule,
  NbStepperModule,
  Ng2SmartTableModule,
  NbAccordionModule,
  GaugeChartModule,
  NbListModule,
  NbWindowModule.forChild(),
  ChartsModule,
  NbProgressBarModule,
  NbDatepickerModule,
];

const entryComponents = [
  AddApplicationPromptComponent,
  AddSoftwareInfoPromptComponent,
  AddConfigurationComponent,
  AddConfigurationPiComponent,
  EditApplicationTaskComponent,
  EditApplicationPiComponent,
  TimeScorePromptComponent,
  TaskScorePromptComponent,
  ConfirmationDialogComponent,
  GaugeChartComponent,
  ChangePasswordComponent,
  TableComponent,
  AddOrganizationDialogComponent,
  InviteUserDialogComponent,
  AssignUsersToGroupDialogComponent,
  AssignUsersToApplicationDialogComponent,
  AssignTraineesToTrainerDialogComponent,
  ImportCsvDialogComponent,
  ImportResultDialogComponent,
];

const components = [
  NgxValidationMessageComponent,
  AddConfigurationComponent,
  GaugeChartComponent,
  PassedFailedComponent,
  SessionComponent,
  SessionReportComponent,
  SessionConfigurationPiCardComponent,
  SessionPiCardComponent,
];

@NgModule({
    imports: [
        SharedModule,
        modules,
        NbCheckboxModule,
        NbBadgeModule,
        RouterModule,
    ],
  exports: [components],
  declarations: [...components, entryComponents, DurationPipe],
  entryComponents: [
    entryComponents,
  ],
})
export class ComponentsModule {
}
