import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SvrComponent } from '../../base/svr.component';
import { SeriousVrRoleType } from '@core/interfaces/serious-vr/enums';
import { UserData } from '@core/interfaces/ecommerce/user';
import { SeriousVrUserDTO } from '@core/interfaces/serious-vr/generated-models';
import { EnumToArrayPipe } from '@core/utils/enum';
import { AuthService } from '@core/auth.service';
import { ApplicationData } from '@core/interfaces/ecommerce/application';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { applicationEditClaimRequirement } from '../../../pages/applications/claim-requirement';

@Component({
  selector: 'svr-invite-user-dialog-prompt',
  templateUrl: 'invite-user-dialog.component.html',
  styleUrls: ['invite-user-dialog.component.scss'],
})
export class InviteUserDialogComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'USERS.';

  form: FormGroup = null;
  currentUser: SeriousVrUserDTO = null;
  enumToPipeArray = new EnumToArrayPipe();
  canChangeApplication: boolean = false;
  applicationsList = this.call(this.applicationService.options())
    .pipe(switchMap(data => of(data.items)));
  organizationId: string;

  constructor(protected ref: NbDialogRef<number>,
    private fb: FormBuilder,
    private userService: UserData,
    private authService: AuthService,
    private applicationService: ApplicationData,
    toastrService: NbToastrService) {
    super(toastrService);
  }

  submit() {
    this.call(this.userService.inviteUser(this.form.value)
      .map(result => {
        if (result) {
          this.ref.close(result);
        }
        return result;
      })).subscribe();
  }

  getAllowedRoleOptions() {
    // TODO make transform() typed
    let roles = this.enumToPipeArray.transform(SeriousVrRoleType) as any[];
    if (this.currentUser && this.currentUser.role !== SeriousVrRoleType.SERIOUSVR) {
      roles = roles.filter(role => role.id !== SeriousVrRoleType.SERIOUSVR);
    }
    return roles.map(role => {
      return {
        id: role.id,
        name: this.authService.getCustomRoleName(role.id, false),
      };
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [ Validators.required, Validators.email ]),
      role: this.fb.control('', [Validators.required]),
      organizationId: this.fb.control(this.organizationId, undefined),
      applications: this.fb.control([], [Validators.required]),
    });

    this.call(this.userService.getCurrentUser())
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });

    this.call(this.authService.selectHasAccess(applicationEditClaimRequirement))
      .subscribe(hasAccess => {
        this.canChangeApplication = hasAccess;
      });
  }
}

