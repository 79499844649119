import {Injectable} from '@angular/core';
import {ApplicationsPiBaseService} from '@core/backend/ecommerce/services/pis/applications-pi-base.service';
import {IErrorScore, IPiErrorScoreSettings} from '@core/interfaces/ecommerce/pis/application-pi-error';
import {ErrorPerformanceIndicatorApi} from '@core/backend/ecommerce/api/performance-indicators/applications-pi.error.api';

@Injectable()
export class ApplicationsPiErrorService extends ApplicationsPiBaseService<IErrorScore,
  IPiErrorScoreSettings, ErrorPerformanceIndicatorApi> {
  constructor(api: ErrorPerformanceIndicatorApi) {
    super(api);
  }
}
