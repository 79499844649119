import {Component, OnInit} from '@angular/core';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ApplicationData} from '@core/interfaces/ecommerce/application';
import 'rxjs-compat/add/operator/map';
import {SvrComponent} from '@shared/base/svr.component';

@Component({
  selector: 'svr-add-application-prompt',
  templateUrl: 'add-application.component.html',
  styleUrls: ['add-application.component.scss'],
})
export class AddApplicationPromptComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'APPLICATIONS.';

  form: FormGroup = null;

  get key() {
    return this.form.get('key');
  }

  get name() {
    return this.form.get('name');
  }

  get description() {
    return this.form.get('description');
  }

  protected readonly unsubscribe$ = new Subject<void>();

  constructor(protected ref: NbDialogRef<number>,
              private fb: FormBuilder,
              private applicationsService: ApplicationData,
              toastrService: NbToastrService) {
    super(toastrService);
    this.form = this.fb.group({
      id: this.fb.control(undefined),
      name: this.fb.control('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)]),
      key: this.fb.control('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50)]),
      description: this.fb.control('', [
        Validators.maxLength(250)],
        ),
    });
  }

  ngOnInit(): void {
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    const form = this.form;
    this.modelStateMessages = {};
    this.call(this.applicationsService.create(form.value)
      .map(result => {
        if (result && result.id) {
          this.ref.close(result.id);
        }
        return result;
      })).subscribe();
  }
}
