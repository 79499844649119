import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbThemeService } from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MenuService } from '@core/menu.service';
import { IMenuItem } from '@core/types';
import { anyApplicationClaimRequirement } from '../../../pages/applications/claim-requirement';
import { sessionViewClaimRequirement } from '../../../pages/sessions/claim-requirement';
import { userManagementViewClaimRequirement } from '../../../pages/users/claim-requirement';
import { anyOrganizationClaimRequirement } from '../../../pages/organizations/claim-requirement';
import { UsersService } from '@core/backend/ecommerce/services/users.service';
import { AuthService } from '@core/auth.service';
import { SeriousVrRoleType } from '@core/interfaces/serious-vr/enums';
import { CookieService } from 'ngx-cookie-service';
import { Locales } from '@core/interfaces/serious-vr/enums';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  readonly translationPrefix = 'NAVIGATION.';
  userPictureOnly: boolean = false;
  user: any;
  menu: NbMenuItem[];
  currentTheme = 'seriousvr';
  userMenu = this.getMenuItems();
  private destroy$: Subject<void> = new Subject<void>();

  menuItems: IMenuItem[];
  lang: Locales;
  locales: typeof Locales = Locales;

  constructor(private translate: TranslateService,
    private router: Router,
    private themeService: NbThemeService,
    private usersService: UsersService,
    private breakpointService: NbMediaBreakpointsService,
    private menuService: MenuService,
    private authService: AuthService,
    private cookies: CookieService) {

    this.lang = cookies.get('lang') as Locales;
    this.lang = this.lang ? this.lang : (translate.getDefaultLang() as Locales);

    this.translate.onLangChange.subscribe(event => {
      this.lang = event.lang as Locales;
      this.cookies.set('lang', event.lang);
      this.reloadMenu();
      this.userMenu = this.getMenuItems();
    });
    this.reloadMenu();
  }

  reloadMenu() {
    this.menuItems = [
      {
        title: this.translate.instant(this.translationPrefix + 'APPLICATIONS'),
        icon: '',
        link: '/pages/applications/list',
        data: {
          claimRequirement: anyApplicationClaimRequirement,
        },
      },
      {
        title: this.translate.instant(this.translationPrefix + 'RESULTS'),
        icon: '',
        link: '/pages/sessions/list',
        data: {
          claimRequirement: sessionViewClaimRequirement,
        },
      },
      {
        title: this.translate.instant(this.authService.getCustomRoleName(SeriousVrRoleType.USER, true)),
        icon: '',
        link: '/pages/trainees/list',
        data: {
          claimRequirement: userManagementViewClaimRequirement,
        },
      },
      {
        title: this.translate.instant(this.translationPrefix + 'USERS'),
        icon: '',
        link: '/pages/users/list',
        data: {
          claimRequirement: userManagementViewClaimRequirement,
        },
      }, {
        title: this.translate.instant(this.translationPrefix + 'ORGANIZATIONS'),
        icon: '',
        link: '/pages/organizations',
        data: {
          claimRequirement: anyOrganizationClaimRequirement,
        },
      },
    ];

    this.initMenu();
  }

  getMenuItems() {
    const items = [];

    items.push({
      title: this.translate.instant(this.translationPrefix + 'PROFILE'),
      link: '/pages/users/current/',
      queryParams: {
        profile: true,
      },
    });

    if (this.user && this.user.organizationId > 0 && this.user.role !== SeriousVrRoleType.USER) {
      items.push({
        title: this.translate.instant(this.translationPrefix + 'ORGANIZATION'),
        link: '/pages/organizations/current/',
      });
    }

    items.push({
      title: this.translate.instant(this.translationPrefix + 'LOG_OUT'),
      link: '/auth/logout',
    });

    return items;
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.usersService.getCurrentUser().subscribe(
      user => {
        if (user) {
          this.user = user;

          this.userMenu = this.getMenuItems();
        }
      });

    this.userMenu = this.getMenuItems();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.reloadMenu();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initMenu() {
    this.menuItems.forEach(item => {
      this.menuService.processMenuItem(item, this.destroy$);
    });
  }

  navigateHome() {
    this.router.navigate(['pages', 'dashboard']);
    this.reloadMenu();
    return false;
  }

  switchLanguage(lang: Locales): void {
    this.translate.use(lang);
  }
}
