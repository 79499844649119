import {ClaimRequirement} from '@core/claim-requirement';
import {Claim} from '@core/claim';

export const applicationViewClaimRequirement = ClaimRequirement.byAll(Claim.ApplicationView);
export const applicationEditClaimRequirement = ClaimRequirement.byAll(Claim.ApplicationEdit);
export const applicationRemoveClaimRequirement = ClaimRequirement.byAll(Claim.ApplicationRemove);

export const anyApplicationClaimRequirement = ClaimRequirement.byAny(
  applicationViewClaimRequirement,
  applicationEditClaimRequirement,
  applicationRemoveClaimRequirement,
);
