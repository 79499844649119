import {SmartTableDataSource} from '@core/backend/common/state/smart-table-data-source';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';
import {ApiBase} from '@core/backend/ecommerce/api/api-base';

export interface IServiceBase<TEntity, TApi extends ApiBase<TEntity>> {
  gridDataSource(id: number): SmartTableDataSource;
  tableDataSource: SmartTableDataSource;
  list(pageNumber: number, pageSize: number): Observable<GridData<TEntity>>;
  get(id: number): Observable<TEntity>;
  create(entity: any): Observable<TEntity>;
  update(entity: any): Observable<TEntity>;
  delete(id: number): Observable<boolean>;
}

export abstract class ServiceBase<TEntity, TApi extends ApiBase<TEntity>>
  implements IServiceBase<TEntity, TApi> {

  protected constructor(protected api: TApi) { }

// todo uitfaseren?
// todo add create/update interfaces to generics
  gridDataSource(id: number): SmartTableDataSource {
    return this.api.dataSource;
  }

  get tableDataSource(): SmartTableDataSource {
    return this.api.dataSource;
  }

  list(pageNumber: number = 1, pageSize: number = 10): Observable<GridData<TEntity>> {
    return this.api.list(pageNumber, pageSize);
  }

  get(id: number): Observable<TEntity> {
    return this.api.get(id);
  }

  create(entity: any): Observable<TEntity> {
    return this.api.add(entity);
  }

  update(entity: any): Observable<TEntity> {
    return this.api.update(entity);
  }

  delete(id: number): Observable<boolean> {
    return this.api.delete(id);
  }
}


