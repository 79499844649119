export enum PiGroupType {
  TaskList = 0,
  Progressbar = 1,
}

export enum SeriousVrRoleType {
  USER = 1,
  INSTRUCTOR = 2,
  MANAGER = 3,
  SERIOUSVR = 4,
}

export enum PerformanceIndicatorType {
  TimeIndicator = 1,
  TaskIndicator = 2,
  ErrorIndicator = 3,
}

export enum ConfigurationType {
  TRAINING = 1,
  INSTRUCTION = 2,
  ASSESSMENT = 3,
}

export enum UserStatusType {
  INACTIVE = 2,
  ACTIVE = 1,
}

export enum ReportTimeGroupBy {
  PER_SESSION = 0,
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
}

export enum ReportAggregateBy {
  TOTAL_SCORE_PER_SESSION = 0,
  TOTAL_SCORE_PER_PI_GROUP = 1,
  AVERAGE_SCORE_PER_PI_GROUP = 2,
}

export enum ReportIndicatorAggregationBy {
  TOTAL_SCORE_PER_SESSION = 0,
  TOTAL_SCORE_PER_PI_GROUP = 1,
  AVERAGE_SCORE_PER_PI_GROUP = 2,
  AVERAGE_SCORE_PER_PI = 3,
}

export enum Locales {
  English = 'en',
  Dutch = 'nl',
}

export enum InvalidCompareType {
  NONE = 0,
  UNFINISHED_SESSIONS = 1,
  INCOMPATIBLE_APPLICATIONS = 2,
  INCOMPATIBLE_TYPES = 3,
  INCOMPATIBLE_CONFIGURATIONS = 4,
}

