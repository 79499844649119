import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Row} from 'ng2-smart-table/lib/lib/data-set/row';
import {NbToastrService} from '@nebular/theme';
import {SvrComponent} from '@shared/base/svr.component';
import {DataSource} from 'ng2-smart-table/lib/lib/data-source/data-source';

@Component({
  selector: 'svr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<TEntity> extends SvrComponent implements OnInit {

  @Input()
  columns: Object;

  @Input()
  canAdd: boolean = false;

  @Input()
  canDelete: boolean = false;

  @Input()
  canEdit: boolean = false;

  @Output()
  create = new EventEmitter();

  @Output()
  edit = new EventEmitter<TEntity>();

  @Output()
  delete = new EventEmitter<TEntity>();

  dataSource: DataSource | TEntity[];

  settings: Object;

  constructor(toastrService: NbToastrService) {
    super(toastrService);
  }

  get crudConfiguration() {
    const config: any = {
      actions: {
        columnTitle: '',
      },

    };
    if (this.canEdit) {
      config.edit = {
        editButtonContent: '<i class="nb-edit"></i>',
      };
    }
    if (this.canDelete) {
      config.edit = {
        deleteButtonContent: '<i class="nb-trash"></i>',
      };
    }
    return config;
  }

  ngOnInit(): void {
    this.settings = this.createSettings();
  }

  createSettings() {
    return {
      ...this.crudConfiguration,
      mode: 'external',
      columns: this.columns,
    };
  }

  onCreate() {
    this.create.emit();
  }

  onEdit(row: Row) {
    this.edit.emit(row.getData());
  }

  onDelete(row: Row) {
    this.delete.emit(row.getData());
  }
}
