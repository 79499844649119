import {ServerDataSource} from 'ng2-smart-table';
import {HttpParams} from '@angular/common/http';

export class SmartTableDataSource extends ServerDataSource {

  filterArray: {key: string, value: string }[] = [];

  public addCustomFilter(field: string, value: string) {
    this.filterArray.push({key: field, value: value});
  }

  public addFilterRequestParams(httpParams: HttpParams): HttpParams {
    httpParams = super.addFilterRequestParams(httpParams);

    this.filterArray.forEach(function (value) {
      httpParams = httpParams.set(value.key, value.value);
    });

    return httpParams;
  }
}
