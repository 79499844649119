import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {OrganizationRoleNameDTO} from '@core/interfaces/serious-vr/generated-models';
import {OrganizationRoleNameApi} from '@core/backend/ecommerce/api/organization-role.api';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';

export interface IOrganizationRoleNameData extends IServiceBase<OrganizationRoleNameDTO, OrganizationRoleNameApi> {
  options(): Observable<GridData<OrganizationRoleNameDTO>>;
}

export abstract class OrganizationRoleNameData
  extends ServiceBase<OrganizationRoleNameDTO, OrganizationRoleNameApi>
  implements IOrganizationRoleNameData {
  protected constructor(api: OrganizationRoleNameApi) {
    super(api);
  }

  options(): Observable<GridData<OrganizationRoleNameDTO>> {
    return this.api.options();
  }
}
