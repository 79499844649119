import {ApplicationsPiBaseService} from '@core/backend/ecommerce/services/pis/applications-pi-base.service';
import {Injectable} from '@angular/core';
import {TimePerformanceIndicatorApi} from '@core/backend/ecommerce/api/performance-indicators/applications-pi-time.api';
import {IPiTimeScoreSettings, ITimeScore} from '@core/interfaces/ecommerce/pis/application-pi-time';

@Injectable()
export class ApplicationsPiTimeService
  extends ApplicationsPiBaseService<ITimeScore, IPiTimeScoreSettings, TimePerformanceIndicatorApi> {
  constructor(api: TimePerformanceIndicatorApi) {
    super(api);
  }
}
