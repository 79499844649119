import {Component, OnInit} from '@angular/core';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LocalDataSource} from 'ng2-smart-table';
import {TranslateService} from '@ngx-translate/core';
import {ITimeScore} from '@core/interfaces/ecommerce/pis/application-pi-time';
import {ITaskScore} from '@core/interfaces/ecommerce/pis/application-pi-task';
import {ApplicationTaskDTO} from '@core/interfaces/serious-vr/generated-models';
import {formatSecondsToTime} from '@core/utils/date';

@Component({
  selector: 'svr-task-score-prompt',
  templateUrl: './task-score.prompt.component.html',
  styleUrls: ['./task-score-prompt.component.scss'],
})
export class TaskScorePromptComponent implements OnInit {
  readonly translationPrefix = 'APPLICATIONS.PI.';

  tasks: Array<ApplicationTaskDTO>;
  taskScore: ITaskScore;
  form: FormGroup = null;
  timeForm: FormGroup = null;

  timeData: ITimeScore[] = [];
  timeTableDataSource: LocalDataSource;
  timeTableSettings: any;

  constructor(protected ref: NbDialogRef<ITaskScore>,
              private translate: TranslateService,
              private toastrService: NbToastrService,
              private fb: FormBuilder) {
    this.timeTableDataSource = new LocalDataSource(this.timeData);

    this.form = this.fb.group({
      taskId: this.fb.control(undefined, [Validators.required]),
      score: this.fb.control(undefined, [Validators.required]),
    });

    this.timeForm = this.fb.group({
      seconds: this.fb.control(undefined, [Validators.required]),
      score: this.fb.control(undefined, [Validators.required]),
    });

    translate.onLangChange.subscribe(_ => {
      this.initSettings();
    });
    this.initSettings();
  }

  get taskId() {
    return this.form.get('taskId');
  }

  get score() {
    return this.form.get('score');
  }

  get timeScore() {
    return this.timeForm.get('score');
  }

  get seconds() {
    return this.timeForm.get('seconds');
  }

  async ngOnInit(): Promise<void> {
    if (this.taskScore) {
      this.form.setValue({
        taskId: this.taskScore.taskId,
        score: this.taskScore.score,
      });

      await this.timeTableDataSource.load(this.taskScore.timeScores);
    }
  }

  async add() {
    this.ref.close({
      taskId: this.taskId.value,
      score: this.score.value,
      timeScores: await this.timeTableDataSource.getAll(),
    });
  }

  async onTimeAdd() {
    const timeData = await this.timeTableDataSource.getAll();
    const existingValue = timeData.filter(sd => sd.seconds.toString() === this.seconds.value);
    if (!existingValue || existingValue.length === 0) {
      await this.timeTableDataSource.add({
        seconds: this.seconds.value,
        score: this.timeScore.value,
      });
      this.timeTableDataSource.refresh();
      this.timeForm.reset();
    } else {
      this.toastrService.warning('', this.translate.instant(`${this.translationPrefix}DUPLICATE_TIME`));
    }
  }

  initSettings() {
    this.timeTableSettings = {
      actions: {
        add: false,
        edit: false,
        delete: true,
        columnTitle: '',
      },
      delete: {
        deleteButtonContent: '<i class=nb-trash></i>',
      },
      columns: {
        seconds: {
          title: this.translate.instant('COMMON.TIME'),
          type: 'number',
          valuePrepareFunction: (cell, _) => formatSecondsToTime(cell),
          filter: false,
        },
        score: {
          title: this.translate.instant('COMMON.SCORE'),
          type: 'number',
          filter: false,
        },
      },
    };
  }
}
