import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ClaimRequirement} from '@core/claim-requirement';
import {AuthService} from '@core/auth.service';
import {Claim} from '@core/claim';

@Directive({
  selector: '[appClaimRequirement]',
})
export class ClaimRequirementDirective implements OnInit, OnDestroy {

  private _onDestroy$: Subject<void> = new Subject();

  @Input() appClaimRequirement: ClaimRequirement<Claim>;

  constructor(private el: ElementRef, private authService: AuthService) {
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    this.authService.selectHasAccess(this.appClaimRequirement).subscribe(hasAccess => {
      if (!hasAccess) {
        this.el.nativeElement.style.display = 'none';
      }
    });
  }
}
