import {Injectable} from '@angular/core';
import {ApplicationsPiBaseService} from '@core/backend/ecommerce/services/pis/applications-pi-base.service';
import {TaskPerformanceIndicatorApi} from '@core/backend/ecommerce/api/performance-indicators/applications-pi.task.api';
import {IPiTaskScoreSettings, ITaskScore} from '@core/interfaces/ecommerce/pis/application-pi-task';

@Injectable()
export class ApplicationsPiTaskService extends
  ApplicationsPiBaseService<ITaskScore, IPiTaskScoreSettings, TaskPerformanceIndicatorApi> {
  constructor(api: TaskPerformanceIndicatorApi) {
    super(api);
  }
}
