import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {OrganizationDTO, RecoverOrganizationCommand} from '@core/interfaces/serious-vr/generated-models';
import {OrganizationsApi} from '@core/backend/ecommerce/api/organizations.api';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';

export interface IOrganizationData extends IServiceBase<OrganizationDTO, OrganizationsApi> {
  options(): Observable<GridData<OrganizationDTO>>;
}

export abstract class OrganizationData extends ServiceBase<OrganizationDTO, OrganizationsApi> implements
  IOrganizationData {
  protected constructor(api: OrganizationsApi) {
    super(api);
  }

  options(): Observable<GridData<OrganizationDTO>> {
    return this.api.options();
  }

  current(): Observable<OrganizationDTO> {
    return this.api.current();
  }

  recover(command: RecoverOrganizationCommand): Observable<OrganizationDTO> {
    return this.api.recover(command);
  }
}
