import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SvrComponent } from '../../base/svr.component';
import { UserData } from '@core/interfaces/ecommerce/user';
import { ApplicationData } from '@core/interfaces/ecommerce/application';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'svr-assign-users-to-application-prompt',
  templateUrl: 'assign-users-to-application-dialog.component.html',
  styleUrls: ['assign-users-to-application-dialog.component.scss'],
})
export class AssignUsersToApplicationDialogComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'USERS.';

  form: FormGroup = null;
  userIds: number[];
  applicationsList = this.call(this.applicationService.options())
    .pipe(switchMap(data => of(data.items)));

  constructor(protected ref: NbDialogRef<number>,
    private fb: FormBuilder,
    private userService: UserData,
    private applicationService: ApplicationData,
    toastrService: NbToastrService) {
    super(toastrService);
  }

  submit() {
    this.call(this.userService.assignToApplications(this.userIds, this.form.value.applications)).subscribe(_ => {
      this.ref.close(true);
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      applications: this.fb.control([], [Validators.required]),
    });
  }
}

