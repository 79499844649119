import { Observable } from 'rxjs';

export interface Formula {
    formula: string;
    applicationId?: number | null;
    placeholders?: any | null;
}

export interface FormulaCompileResultDTO {
    success: boolean;
    errors?: string[] | null;
}

export interface FormulaRunResultDTO {
    success: boolean;
    score?: number | null;
    errors?: string[] | null;
}

export abstract class FormulaData {
    abstract compileFormula(formula: string): Observable<FormulaCompileResultDTO>;
    abstract compileApplication(formula: string, applicationId: number): Observable<FormulaCompileResultDTO>;
    abstract testFormula(formula: Formula): Observable<FormulaCompileResultDTO>;
}
