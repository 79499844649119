import * as moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const DATE_LONGTIME_FORMAT = 'LL HH:mm:ss';
export const SHORT_DATE_FORMAT = 'l';
export const SHORT_TIME_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = 'LLLL';
export const SHORT_DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const getMomentLocal = (d: moment.MomentInput) => moment.utc(d).local();

export const formatDate = (d: moment.MomentInput, s = DEFAULT_DATE_FORMAT) => {
  if (d) {
    return getMomentLocal(d).format(s);
  }
  return '-';
};

export const formatDateShort = (d: moment.MomentInput) => {
  if (d) {
    return getMomentLocal(d).format(SHORT_DATE_FORMAT);
  }
  return '-';
};

export const formatTimeShort = (d: moment.MomentInput) => {
  if (d) {
    return getMomentLocal(d).format(SHORT_TIME_FORMAT);
  }
  return '-';
};

export const formatDateTime = (d: moment.MomentInput) => {
  if (d) {
    return getMomentLocal(d).format(DATE_TIME_FORMAT);
  }
  return '-';
};

export const formatDateTimeShort = (d: moment.MomentInput) => {
  if (d) {
    return getMomentLocal(d).format(SHORT_DATE_TIME_FORMAT);
  }
  return '-';
};

export const formatISODate = (d: moment.MomentInput) => {
  if (d) {
    return moment(d).toISOString();
  }
  return '-';
};

export const formatSecondsToTime = (d: number) => {
  if (d) {
    return moment.utc(d * 1000).format('HH:mm:ss');
  }
  return '-';
};


export default {
  formatDate,
  formatDateShort,
  formatDateTime,
  formatTimeShort,
  formatDateTimeShort,
  formatSecondsToTime,
};
