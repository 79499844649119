import {Component, Input, OnInit, Optional} from '@angular/core';
import {SvrComponent} from '@shared/base/svr.component';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {PerformanceIndicatorData, ApplicationPi} from '@core/interfaces/ecommerce/pis/application-pi-base';
import {switchMap} from 'rxjs/operators';
import {ConfigurationPiService} from '@core/backend/ecommerce/services/configuration-pi.service';
import {ConfigurationPiDTO, ApplicationPiDTO} from '@core/interfaces/serious-vr/generated-models';
import {PerformanceIndicatorType} from '@core/interfaces/serious-vr/enums';

@Component({
  selector: 'svr-add-configuration-pi',
  templateUrl: './add-configuration-pi.component.html',
  styleUrls: ['./add-configuration-pi.component.scss'],
})
export class AddConfigurationPiComponent extends SvrComponent implements OnInit {
  readonly translationPrefix = 'CONFIGURATIONS.PI.';

  form: FormGroup = null;
  applicationPis$: Observable<ApplicationPi[]>;

  selectedPi: ApplicationPiDTO;
  selectedType: PerformanceIndicatorType = null;

  @Input() configurationPi: ConfigurationPiDTO;
  @Input() applicationId: number;
  @Input() configurationId: number;

  private editMode = false;

  constructor(toastrService: NbToastrService,
              private translate: TranslateService,
              @Optional() protected ref: NbDialogRef<number>,
              private configurationPiService: ConfigurationPiService,
              private applicationPiService: PerformanceIndicatorData,
              private fb: FormBuilder,
  ) {
    super(toastrService);
  }

  ngOnInit() {
    this.applicationPis$ = this.call(
      this.applicationPiService.list(1, 100, this.configurationPi ?
        this.configurationPi.configuration.applicationId : this.applicationId)
        .pipe(
          switchMap(d => of(d.items)),
        ));

    this.editMode = (this.configurationPi && this.configurationPi.id > 0);
    this.initForm();
  }

  initForm() {
    if (this.configurationPi) {
      this.selectedPi = this.configurationPi.applicationPi ? this.configurationPi.applicationPi : undefined;
      this.selectedType = this.selectedPi ? this.selectedPi.type : null;

      this.form = this.fb.group({
        id: this.initComponent(this.configurationPi.id),
        configurationId: this.initComponent(this.configurationId),
        applicationPiId: this.initComponent(this.selectedPi.id, undefined),
        scoreToPass: this.initComponent(this.configurationPi.scoreToPass),
      });
    } else {
      this.form = this.fb.group({
        id: this.initComponent(null),
        configurationId: this.initComponent(this.configurationId),
        applicationPiId: this.initComponent(null),
        scoreToPass: this.initComponent(null),
      });
    }
  }

  piSelected($event) {
    this.applicationPis$.subscribe(x => {
      const result = x.find(y => y.id === $event);
      this.selectedType = result.type;

      if (this.isErrorIndicator()) {
        this.form.controls['scoreToPass'].setValue(undefined);
      } else {
        this.form.controls['scoreToPass'].setValue(result.maxScore);
      }
    });
  }

  submit() {
    const form = this.form;

    const observable = this.editMode
      ? this.configurationPiService.update(form.value) : this.configurationPiService.create(form.value);

    this.call(observable.map(result => {
      if (result) {
        const translationKey = this.translationPrefix + (this.editMode ? 'UPDATED' : 'CREATED');
        this.toastrService.success('', this.translate.instant(translationKey));

        if (this.ref) {
          this.ref.close(result);
        }
      }
    })).subscribe();
  }

  isErrorIndicator() {
    return this.selectedType && this.selectedType === PerformanceIndicatorType.ErrorIndicator;
  }

  private initComponent<T>(data: T, whenNull: string | number = ''): FormControl {
    return this.fb.control(data ? data : whenNull);
  }
}
