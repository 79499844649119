import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';
import {ApplicationTaskDTO} from '@core/interfaces/serious-vr/generated-models';
import {ApplicationErrorApi} from '@core/backend/ecommerce/api/application-error.api';

export interface IApplicationErrorData extends IServiceBase<ApplicationTaskDTO, ApplicationErrorApi> {
}

export abstract class ApplicationErrorData extends ServiceBase<ApplicationTaskDTO, ApplicationErrorApi>
  implements IApplicationErrorData {
  constructor(api: ApplicationErrorApi) {
    super(api);
  }

  abstract getErrorsForApplication(applicationId: number): Observable<GridData<ApplicationTaskDTO>>;
}
