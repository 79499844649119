import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SessionData} from '@core/interfaces/ecommerce/session';
import {Subject} from 'rxjs';
import {SessionDTO, SessionPiGroupDTO} from '@core/interfaces/serious-vr/generated-models';
import {PiGroupType} from '@core/interfaces/serious-vr/enums';

@Component({
  selector: 'svr-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnInit, OnDestroy {

  readonly translationPrefix = 'SESSION.';
  public session: SessionDTO;
  protected readonly unsubscribe$ = new Subject<void>();
  private passed: number;
  // tslint:disable
  private failed: number;
  @Input()
  sessionId: string;
  sessionPiGroups: any;

  constructor(
    private sessionsService: SessionData,
    private router: Router) {
  }

  ngOnInit(): void {
    this.loadData(this.sessionId);
  }

  loadData(id) {
    if (id) {
      this.sessionsService.get(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(session => {
          this.session = session;

          if (session && session.sessionPis) {
            this.passed = session.sessionPis.filter(x => x.passed).length;
            this.failed = session.sessionPis.length - this.passed;
            this.session.sessionPis = session.sessionPis.sort(this.compare);

            var emptyPiGroup: SessionPiGroupDTO = {
              key: '',
              name: '',
            };
        
            this.sessionPiGroups = [emptyPiGroup].concat(this.session.sessionPiGroups.sort(this.compare))
            .map(piGroup => {
              const infoGroups = [];
              const relevantSessionPis = this.session.sessionPis.filter(pi => pi.sessionPiSessionPiGroups && pi.sessionPiSessionPiGroups.some(group => group.sessionPiGroupId === piGroup.id));
        
              relevantSessionPis.forEach(pi => pi.scoreInfoGroups.forEach(infoGroup => {
                if (infoGroup.scoreInfoDetails && infoGroup.scoreInfoDetails.length > 0) {
                  infoGroups.push(infoGroup);
                }
              }));
        
              const sumScore = Math.max(0, relevantSessionPis.length > 0 ?
                relevantSessionPis.map(sessionPi => sessionPi.score).reduce((accumulator, score) => accumulator + score) : 0);
              const passed = relevantSessionPis.length > 0 ? 
                relevantSessionPis.map(sessionPi => (Number)(!sessionPi.passed ? 1 : 0))
                  .reduce((accumulator, failed) => accumulator + failed) === 0 : false;
              const maxScore =  Math.max(0, relevantSessionPis.length > 0 ?
                relevantSessionPis.map(sessionPi => sessionPi.maxScore).reduce((accumulator, maxScore) => accumulator + maxScore) : 0);

              return {
                name: piGroup.name,
                description: piGroup.description,
                type: piGroup.type,
                score: sumScore,
                passed: passed,
                maxScore: maxScore,
                infoGroups: infoGroups.map(infoGroup => {
                  const details = new Map();
                  infoGroup.scoreInfoDetails.forEach(infoDetail => {
                    if (!details.has(infoDetail.key)) {
                      details.set(infoDetail.key, {});
                    }
                    const detail = details.get(infoDetail.key);
                    detail.key = infoDetail.key;
                    if (infoDetail.propertyName) {
                      detail[infoDetail.propertyName.toLowerCase()] = infoDetail.value;
                    }
                  });
                  return {
                    name: infoGroup.name,
                    infoDetails: Array.from(details.values()),
                  };
                }),
              };
            }).filter(piGroup => piGroup.infoGroups.length > 0);
          };
        });
    }
  }

  compare(a: SessionPiGroupDTO, b: SessionPiGroupDTO): number {
    return a.order - b.order;
  }

  isProgressbar(type: PiGroupType) {
    return type === PiGroupType.Progressbar;
  }

  getTaskTime(taskKey: string) {
    if (!this.session) return null;
    const tasks = this.session.sessionTasks.filter(task => task.applicationTaskInfo.key === taskKey)
      .map(task => task.duration);
    return tasks && tasks.length > 0 ? tasks[0] : null;
  }

  getColor(taskKey: string) {
    const colors = this.session.sessionPis.filter(sessionPi => 
      sessionPi.scoreInfoGroups.some(infoGroup => infoGroup.scoreInfoDetails.some(details => details.key == taskKey)))
      .map(sessionPi => sessionPi.color);
    return colors && colors.length > 0 ? colors[0] : 'inherit';
  }

  back() {
    this.router.navigate(['pages', 'sessions', 'list']);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
