import { Component, Input, OnInit } from '@angular/core';
import { SessionData } from '@core/interfaces/ecommerce/session';
import { SessionReportDTO, IndicatorOccurrencesDTO } from '@core/interfaces/serious-vr/generated-models';
import { ReportTimeGroupBy, ReportAggregateBy, ReportIndicatorAggregationBy, InvalidCompareType } from '@core/interfaces/serious-vr/enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'svr-session-report',
    templateUrl: './session-report.component.html',
    styleUrls: ['./session-report.component.scss'],
})
export class SessionReportComponent implements OnInit {

    readonly translationPrefix = 'SESSION.REPORT.';

    constructor(
      private translate: TranslateService,
      private sessionService: SessionData) {
    }

    timeGroupBy: ReportTimeGroupBy = ReportTimeGroupBy.PER_SESSION;
    aggregateBy: ReportAggregateBy = ReportAggregateBy.TOTAL_SCORE_PER_SESSION;
    radarAggregateBy: ReportIndicatorAggregationBy = ReportIndicatorAggregationBy.TOTAL_SCORE_PER_PI_GROUP;
    timeRange: any;
    report: SessionReportDTO;
    occurrences: IndicatorOccurrencesDTO;
    lineData: any = [];
    radarData: any = [];
    labels: string[] = [];
    radarLabels: string[] = [];
    @Input() sessionIds: number[];
    invalidCompareType: typeof InvalidCompareType = InvalidCompareType;
    lineGraphOptions: any;
    radarGraphOptions: any;

    ngOnInit() {
      this.initOccurrences();
      this.reload();
      this.reloadRadar();

      this.lineGraphOptions = {
        scaleShowVerticalLines: false,
        responsive: true,
        scales: {
          yAxes: [{
              ticks: {
                  min: 0,
              },
          }],
        },
      };

      this.radarGraphOptions = { scaleShowVerticalLines: false, responsive: true };
    }

    initOccurrences() {
      this.sessionService.indicatorOccurrences({
        sessionIds: this.sessionIds,
      })
        .pipe()
        .subscribe(occurrences => {
          this.occurrences = occurrences;
        });
    }

    reload() {
      this.sessionService.report({
        sessionIds: this.sessionIds,
        timeGroupBy: this.timeGroupBy,
        aggregateBy: this.aggregateBy,
        startDate: this.timeRange && this.timeRange.start ? this.timeRange.start : undefined,
        endDate: this.timeRange && this.timeRange.end ? this.timeRange.end : undefined,
      })
        .pipe()
        .subscribe(result => {
          this.report = result;
          if (!result.labels || !result.scores) return;
          this.labels = result.labels;
          this.lineData = result.scores.map(group => {
            return {
              data: group.points,
              label: group.name && group.name.length > 0 ? group.name :
                this.translate.instant(this.translationPrefix + 'NONE'),
              spanGaps: true,
              lineTension: 0,
              tooltips: {
                enabled: false,
              },
            };
          });
        });
    }

    reloadRadar() {
      this.sessionService.reportRadar({
        sessionIds: this.sessionIds,
        aggregateBy: this.radarAggregateBy,
      })
      .pipe()
      .subscribe(result => {
        if (!result.labels || !result.scores) return;
        this.radarLabels = result.labels.map(label => label && label.length > 0 ?
          label : this.translate.instant(this.translationPrefix + 'NONE'));
        this.radarData = result.scores.map(group => {
          return {
            data: group.points,
            label: group.name && group.name.length > 0 ?
              group.name : this.translate.instant(this.translationPrefix + 'NONE'),
          };
        });
      });
    }

    get userList() {
      if (!this.report || !this.report.users) return [];
      return this.report.users;
    }

    get groupList() {
      if (!this.report || !this.report.groups) return [];
      return this.report.groups;
    }

}
