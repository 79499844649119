import {AfterViewInit, Component, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DefaultFilter} from 'ng2-smart-table';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import * as moment from 'moment';

interface RangeObject {
  start: Date;
  end: Date;
}

@Component({
  selector: 'svr-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss'],
})
export class CalendarFilterComponent extends DefaultFilter implements AfterViewInit, OnChanges {
  inputControl = new FormControl();

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.inputControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.delay),
      )
      .subscribe((value: RangeObject) => {
        if (value) {
          if (value.end) {
            this.query = moment(value.start).toISOString() + ';' + moment(value.end).toISOString();
          } else {
            this.query = moment(value.start).toISOString();
          }
        } else {
          this.query = null;
        }
        this.setFilter();
      });
  }
  ngOnChanges(changes: SimpleChanges): void {}

}
