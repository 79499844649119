import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {ApplicationsApi} from '@core/backend/ecommerce/api/applications.api';
import {ApplicationDTO} from '@core/interfaces/serious-vr/generated-models';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';

export interface IApplicationData extends IServiceBase<ApplicationDTO, ApplicationsApi> {
  options(): Observable<GridData<ApplicationDTO>>;
}

export abstract class ApplicationData extends ServiceBase<ApplicationDTO, ApplicationsApi>
  implements IApplicationData  {
  protected constructor(api: ApplicationsApi) {
    super(api);
  }

  options(): Observable<GridData<ApplicationDTO>> {
    return this.api.options();
  }
}
