import {HttpService} from '@core/backend/common/api/http.service';
import {SmartTableDataSource} from '@core/backend/common/state/smart-table-data-source';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';
import {HttpParams} from '@angular/common/http';

export abstract class ApiBase<TEntity> {
  abstract readonly apiController: string;

  protected constructor(protected httpService: HttpService) {}

  get dataSource(): SmartTableDataSource {
    const source = this.httpService.getServerDataSource(`${this.httpService.apiUrl}/${this.apiController}`);
    return source;
  }

  list(pageNumber: number = 1,
       pageSize: number = 10,
       parameters: HttpParams = new HttpParams()): Observable<GridData<TEntity>> {
    let params = parameters;
    params = params
      .append('pageNumber', `${pageNumber}`)
      .append('pageSize', `${pageSize}`);
    return this.httpService.get(this.apiController, {params});
  }

  get(id: number): Observable<TEntity> {
    return this.httpService.get(`${this.apiController}/${id}`);
  }

  delete(id: number): Observable<boolean> {
    return this.httpService.delete(`${this.apiController}/${id}`);
  }

  add(item: any): Observable<TEntity> {
    return this.httpService.post(this.apiController, item);
  }

  update(item: any): Observable<TEntity> {
    return this.httpService.put(`${this.apiController}`, item);
  }
}
