import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UserStore} from '@core/stores/user.store';
import {Injectable} from '@angular/core';
import {UserData} from '@core/interfaces/ecommerce/user';
import {SeriousVrUserDTO} from '@core/interfaces/serious-vr/generated-models';

@Injectable()
export class InitUserService {
  constructor(protected userStore: UserStore,
              protected usersService: UserData) {
  }

  initCurrentUser(): Observable<SeriousVrUserDTO> {
    return this.usersService.getCurrentUser()
      .pipe(tap((user: SeriousVrUserDTO) => {
        if (user) {
          this.userStore.setUser(user);
          return user;
        }
      }));
  }
}
