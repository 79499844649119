export enum ClaimRequirementOperator {
  All,
  Any,
}

export class ClaimRequirement<T = string> {

  public static byAny<T = string>(...claims: Array<T | ClaimRequirement<T>>): ClaimRequirement<T> {
    return new ClaimRequirement(ClaimRequirementOperator.Any, ...claims);
  }

  public static byAll<T = string>(...claims: Array<T | ClaimRequirement<T>>): ClaimRequirement<T> {
    return new ClaimRequirement(ClaimRequirementOperator.All, ...claims);
  }

  private readonly _operatorFn: (callback: (c: T | ClaimRequirement<T>) => boolean) => boolean;
  private readonly _claims: Array<T | ClaimRequirement<T>>;

  constructor(operator: ClaimRequirementOperator, ...claims: Array<T | ClaimRequirement<T>>) {
    this._operatorFn = (operator === ClaimRequirementOperator.All ? claims.every : claims.some).bind(claims);
    this._claims = claims;
  }

  public get claims() {
    return this._claims;
  }

  public hasAccess(availableClaims: T[]): boolean {
    return this._operatorFn(c => {
      if (c instanceof ClaimRequirement) {
        return c.hasAccess(availableClaims);
      } else {
        return availableClaims.includes(c);
      }
    });
  }
}
