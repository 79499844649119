import {
  ICreateIndicator,
  IPiSettingsDto,
  IScore,
  PerformanceIndicatorBaseData,
} from '@core/interfaces/ecommerce/pis/application-pi-base';
import {TimePerformanceIndicatorApi} from '@core/backend/ecommerce/api/performance-indicators/applications-pi-time.api';

export interface ICreateTimeIndicator extends ICreateIndicator<ITimeScore, IPiTimeScoreSettings> {
}

export interface ITimeScore extends IScore {
  seconds: number;
}

export interface IUpdateTimeIndicator extends ICreateTimeIndicator {
  id: number;
}

export interface IPiTimeScoreSettings extends IPiSettingsDto<ITimeScore> {
}

export abstract class TimePerformanceIndicatorData
  extends PerformanceIndicatorBaseData<ITimeScore, IPiTimeScoreSettings, TimePerformanceIndicatorApi> {
}
