import {Component, Input} from '@angular/core';
import {ItemInfoDTO, SessionPiDTO} from '@core/interfaces/serious-vr/generated-models';
import {ChartColors} from '@shared/gauge-chart/gauge-chart.component';

@Component({
  selector: 'svr-session-pi-card',
  templateUrl: './session-pi-card.component.html',
  styleUrls: ['./session-pi-card.component.scss'],
})
export class SessionPiCardComponent {

  @Input()
  sessionPi: SessionPiDTO;
  colors = ChartColors;

  isErrorIndicator(piDto: ItemInfoDTO) {
    return piDto && piDto.key.includes('ApplicationErrorIndicator');
  }
}
