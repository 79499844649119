import {IPiSettingsDto, IScore, PerformanceIndicatorBaseData} from '@core/interfaces/ecommerce/pis/application-pi-base';
import {PerformanceIndicatorApiBase} from '@core/backend/ecommerce/api/performance-indicators/applications-pi-base.api';
import {Injectable} from '@angular/core';

export class ApplicationsPiBaseService<TScore extends IScore, TSettingsDto extends IPiSettingsDto<TScore>,
  TApi extends PerformanceIndicatorApiBase<TSettingsDto>>
  extends PerformanceIndicatorBaseData<TScore, TSettingsDto, TApi> {
}

@Injectable()
export class ApplicationPiService
  extends ApplicationsPiBaseService<IScore,
  IPiSettingsDto<IScore>,
  PerformanceIndicatorApiBase<IPiSettingsDto<IScore>>> {
  constructor(api: PerformanceIndicatorApiBase<IPiSettingsDto<IScore>>) {
    super(api);
  }
}
