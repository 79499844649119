import {Injectable} from '@angular/core';
import {ConfigurationPiData} from '@core/interfaces/ecommerce/configurations/configurations-pi';
import {SmartTableDataSource} from '@core/backend/common/state/smart-table-data-source';

@Injectable()
export class ConfigurationPiService extends ConfigurationPiData {
  gridDataSource(id: number): SmartTableDataSource {
    const src = this.api.dataSource;
    src.addCustomFilter('filterByConfigurationId', `${id}`);
    return src;
  }
}
