import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormulaApi } from '../api/formula.api';
import { Formula, FormulaData, FormulaCompileResultDTO } from '@core/interfaces/common/formula';

@Injectable()
export class FormulaService extends FormulaData {
  constructor(private api: FormulaApi) {
    super();
  }

  compileFormula(formula: string): Observable<FormulaCompileResultDTO> {
    return this.api.compileFormula({ formula: formula });
  }

  compileApplication(formula: string, applicationId: number): Observable<FormulaCompileResultDTO> {
    return this.api.compileApplication({ formula: formula, applicationId: applicationId });
  }

  testFormula(formula: Formula): Observable<FormulaCompileResultDTO> {
    return this.api.testFormula(formula);
  }

  testApplication(formula: Formula): Observable<FormulaCompileResultDTO> {
    return this.api.testApplication(formula);
  }
}
