import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {Subject} from 'rxjs';
import {ITimeScore} from '@core/interfaces/ecommerce/pis/application-pi-time';

@Component({
  selector: 'svr-time-prompt',
  templateUrl: './time-score-prompt.component.html',
  styleUrls: ['./time-score-prompt.component.scss'],
})
export class TimeScorePromptComponent implements OnInit {
  form: FormGroup = null;
  timeScore: ITimeScore = null;

  protected readonly unsubscribe$ = new Subject<void>();

  constructor(protected ref: NbDialogRef<ITimeScore>,
              private fb: FormBuilder) {
    this.form = this.fb.group({
      seconds: this.fb.control(undefined, [Validators.required, Validators.min(1)]),
      score: this.fb.control(undefined, [Validators.required, Validators.min(1)]),
    });
  }

  get seconds() {
    return this.form.get('seconds');
  }

  get score() {
    return this.form.get('score');
  }

  ngOnInit(): void {
    if (this.timeScore) {
      this.form.setValue({
        seconds: this.timeScore.seconds,
        score: this.timeScore.score,
      });
    }
  }

  add() {
    this.ref.close(this.form.value);
  }
}
