import {UserData} from '@core/interfaces/ecommerce/user';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {NbAuthService} from '@nebular/auth';
import {UsersApi} from '@core/backend/ecommerce/api/users.api';
import {SeriousVrUserDTO, ImportedUserDTO} from '@core/interfaces/serious-vr/generated-models';

@Injectable()
export class UsersService extends UserData {

  constructor(private authService: NbAuthService, api: UsersApi) {
    super(api);
  }

  getCurrentUser(): Observable<SeriousVrUserDTO> {
    return this.authService.isAuthenticated()
      .pipe(
        switchMap(authenticated => {
          return authenticated ? this.api.getCurrent() : of(null);
        }),
        map(u => {
          if (u && !u.setting) {
            u.setting = {};
          }
          return u;
        }));
  }

  // TODO make typed
  updateCurrent(user: any): Observable<SeriousVrUserDTO> {
    return this.api.updateCurrent(user);
  }

  changePassword(userId: number, password: string, confirmPassword: string) {
    return this.api.changePassword(userId, password, confirmPassword);
  }

  // TODO make typed
  inviteUser(user: any) {
    return this.api.inviteUser(user);
  }

  assignToGroup(userIds: number[], groupTag: string) {
    return this.api.assignToGroup(userIds, groupTag);
  }

  assignToTrainer(traineeIds: number[], trainerId: number) {
    return this.api.assignToTrainer(traineeIds, trainerId);
  }

  assignToApplications(userIds: number[], applicationIds: number[]) {
    return this.api.assignToApplications(userIds, applicationIds);
  }

  importCsvTemplate(): Observable<Blob> {
    return this.api.importCsvTemplate();
  }

  importCsv(file: File): Observable<ImportedUserDTO[]> {
    return this.api.importCsv(file);
  }
}
