import {IScore, PerformanceIndicatorBaseData} from '@core/interfaces/ecommerce/pis/application-pi-base';
import {ErrorPerformanceIndicatorApi} from '@core/backend/ecommerce/api/performance-indicators/applications-pi.error.api';

export interface ICreateErrorIndicator {
  applicationId: number;
  name: string;
  description: string;
  implementationKey: string;
  minVersionId: number;
  maxVersionId: number;
  settings: IPiErrorScoreSettings;
}

export interface IUpdateErrorIndicator extends ICreateErrorIndicator {
  id: number;
}

export interface IErrorScore extends IScore {
  applicationErrorId: number;
}

export interface IPiErrorScoreSettings {
  scores: IErrorScore[];
}

export interface IPiErrorScoreCommand {
  settings: IPiErrorScoreSettings;
}

export abstract class ErrorPerformanceIndicatorData
  extends PerformanceIndicatorBaseData<IErrorScore, IPiErrorScoreSettings,
    ErrorPerformanceIndicatorApi> {
}
