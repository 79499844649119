import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NumericDirective} from './directives/numeric.directive';
import {EnumToArrayPipe} from '@core/utils/enum';
import {AuthDirectivesModule} from '../@auth/auth-directives.module';

const modules = [
  AuthDirectivesModule,
  CommonModule,
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
];

const directives = [
  NumericDirective,
];

@NgModule({
  imports: modules,
  exports: [
    ...modules,
    NumericDirective,
    EnumToArrayPipe,
  ],
  declarations: [directives, EnumToArrayPipe, EnumToArrayPipe],
})
export class SharedModule { }
