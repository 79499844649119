import { Component } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { SvrComponent } from '../../base/svr.component';
import { ImportResult } from '../../types';

@Component({
  selector: 'svr-import-result-prompt',
  templateUrl: 'import-result-dialog.component.html',
  styleUrls: ['import-result-dialog.component.scss'],
})
export class ImportResultDialogComponent extends SvrComponent {
  readonly translationPrefix = 'USERS.';
  importResult: ImportResult;

  settings: any;

  constructor(protected ref: NbDialogRef<number>,
    private translate: TranslateService,
    toastrService: NbToastrService) {
    super(toastrService);
    translate.onLangChange.subscribe(_ => {
      this.initSettings();
    });
    this.initSettings();
  }

  print(): void {
    window.print();
  }

  initSettings() {
    this.settings = {
      mode: 'external',
      hideSubHeader: true,
      actions: {
        columnTitle: '',
        add: false,
        edit: false,
        delete: false,
      },
      columns: {
        firstName: {
          title: this.translate.instant('USERS.FIRST_NAME'),
          type: 'string',
        },
        lastName: {
          title: this.translate.instant('USERS.LAST_NAME'),
          type: 'string',
        },
        email: {
          title: this.translate.instant('USERS.EMAIL'),
          type: 'string',
        },
        password: {
          title: this.translate.instant('USERS.PASSWORD'),
          type: 'string',
        },
        groups: {
          title: this.translate.instant('USERS.GROUPS'),
          type: 'string',
        },
        trainerEmail: {
          title: this.translate.instant('USERS.TRAINER'),
          type: 'string',
        },
      },
    };
  }
}

