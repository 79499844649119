import {
  IPiSettingsDto,
  IScore,
  PerformanceIndicatorBaseData,
} from '@core/interfaces/ecommerce/pis/application-pi-base';
import {TaskPerformanceIndicatorApi} from '@core/backend/ecommerce/api/performance-indicators/applications-pi.task.api';
import {ITimeScore} from '@core/interfaces/ecommerce/pis/application-pi-time';

export interface ICreateTaskIndicator {
  applicationId: number;
  name: string;
  description: string;
  implementationKey: string;
  minVersionId: number;
  maxVersionId: number;
  settings: IPiTaskScoreSettings;
}

export interface IUpdateTaskIndicator extends ICreateTaskIndicator {
  id: number;
}

export interface ITaskScore extends IScore {
  taskId: number;
  timeScores: ITimeScore[];
}

export interface IPiTaskScoreSettings extends IPiSettingsDto<ITaskScore> {
}

export interface IPiTaskScoreCommand {
  settings: IPiTaskScoreSettings;
}

export abstract class TaskPerformanceIndicatorData
  extends PerformanceIndicatorBaseData<ITaskScore, IPiTaskScoreSettings,
    TaskPerformanceIndicatorApi> {
}
