export enum Claim {
  SessionView = 'Session_View',
  SessionEdit = 'Session_Edit',
  SessionRemove = 'Session_Remove',
  UserManagementView = 'UserManagement_View',
  UserManagementEdit = 'UserManagement_Edit',
  UserManagementRemove = 'UserManagement_Remove',
  ApplicationView = 'Application_View',
  ApplicationEdit = 'Application_Edit',
  ApplicationRemove = 'Application_Remove',
  OrganizationView = 'Organization_View',
  OrganizationEdit = 'Organization_Edit',
  OrganizationRemove = 'Organization_Remove',
  OrganizationProfileView = 'Organization_Profile_View',
}
