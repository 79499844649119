import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {ApplicationTasksApi} from '@core/backend/ecommerce/api/application-task.api';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';
import {ApplicationTaskDTO} from '@core/interfaces/serious-vr/generated-models';

export interface IApplicationTaskData extends IServiceBase<ApplicationTaskDTO, ApplicationTasksApi> {
}

export abstract class ApplicationTaskData extends ServiceBase<ApplicationTaskDTO, ApplicationTasksApi>
  implements IApplicationTaskData {
  constructor(api: ApplicationTasksApi) {
    super(api);
  }

  abstract getTasksForApplication(applicationId: number): Observable<GridData<ApplicationTaskDTO>>;
}
