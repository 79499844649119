import {IServiceBase, ServiceBase} from '@core/backend/ecommerce/services/service-base';
import {ApplicationTaskGroupApi} from '@core/backend/ecommerce/api/application-task-group.api';
import {Observable} from 'rxjs';
import {GridData} from '@core/interfaces/common/gridData';
import {ApplicationTaskGroupDTO} from '@core/interfaces/serious-vr/generated-models';
import { HttpParams } from '@angular/common/http';

export interface IApplicationTaskGroupData extends IServiceBase<ApplicationTaskGroupDTO, ApplicationTaskGroupApi> {
}

export abstract class ApplicationTaskGroupData extends ServiceBase<ApplicationTaskGroupDTO, ApplicationTaskGroupApi>
    implements IApplicationTaskGroupData {

  constructor(api: ApplicationTaskGroupApi) {
    super(api);
  }

  list(pageNumber: number = 1, pageSize: number = 10, applicationId: number = null):
    Observable<GridData<ApplicationTaskGroupDTO>> {
    const params = new HttpParams().set('FilterByApplicationId', `${applicationId}`);
    return this.api.list(pageNumber, pageSize, params);
  }

  options(): Observable<GridData<ApplicationTaskGroupDTO>> {
    return this.api.options();
  }
}
