import {ClaimRequirement} from '@core/claim-requirement';
import {Claim} from '@core/claim';

export const applicationViewClaimRequirement = ClaimRequirement.byAll(Claim.ApplicationView);
export const applicationEditClaimRequirement = ClaimRequirement.byAll(Claim.ApplicationEdit);
export const applicationRemoveClaimRequirement = ClaimRequirement.byAll(Claim.ApplicationRemove);

export const organizationViewClaimRequirement = ClaimRequirement.byAll(Claim.OrganizationView);
export const organizationEditClaimRequirement = ClaimRequirement.byAll(Claim.OrganizationEdit);
export const organizationRemoveClaimRequirement = ClaimRequirement.byAll(Claim.OrganizationRemove);
export const organizationProfileViewClaimRequirement = ClaimRequirement.byAll(Claim.OrganizationProfileView);

export const anyOrganizationClaimRequirement = ClaimRequirement.byAny(
  organizationViewClaimRequirement,
  organizationEditClaimRequirement,
  organizationRemoveClaimRequirement,
);
