import { Injectable } from '@angular/core';
import {SmartTableDataSource} from '../../common/state/smart-table-data-source';
import {SoftwareInfoTaskData} from '@core/interfaces/ecommerce/application-software-info';

@Injectable()
export class ApplicationSoftwareInfoService extends SoftwareInfoTaskData {

  gridDataSource(id: number): SmartTableDataSource {
    const src = this.api.dataSource;
    src.addCustomFilter('filterByApplicationId', `${id}`);
    return src;
  }
}
