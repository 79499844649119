import {Component, OnInit} from '@angular/core';
import {NbDialogRef, NbToastrService} from '@nebular/theme';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {of, Subject} from 'rxjs';
import 'rxjs-compat/add/operator/takeUntil';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {ApplicationSoftwareInfoService} from '@core/backend/ecommerce/services/application-software-info.service';
import {TranslateService} from '@ngx-translate/core';
import {IModelStateMessages, ModelStateError, ValidationError} from '@core/backend/validation';
import {PiImplementation} from '@core/interfaces/ecommerce/pis/application-pi-base';
import {TimePerformanceIndicatorData} from '@core/interfaces/ecommerce/pis/application-pi-time';
import {TaskPerformanceIndicatorData} from '@core/interfaces/ecommerce/pis/application-pi-task';
import * as fromPerformanceIndicatorType from '@core/interfaces/ecommerce/pis/application-pi';
import {SoftwareInfoDTO, ApplicationPiGroupDTO} from '@core/interfaces/serious-vr/generated-models';
import {PerformanceIndicatorType} from '@core/interfaces/serious-vr/enums';
import {ErrorPerformanceIndicatorData} from '@core/interfaces/ecommerce/pis/application-pi-error';
import {ApplicationPiGroupData} from '@core/interfaces/ecommerce/pis/application-pi-group';

@Component({
  selector: 'svr-edit-application-pi-component',
  templateUrl: './edit-application-pi.component.html',
  styleUrls: ['./edit-application-pi.component.scss'],
})
export class EditApplicationPiComponent implements OnInit {
  readonly translationPrefix = 'APPLICATIONS.PI.';

  form: FormGroup = null;
  applicationId: number;
  softwareInfo: Array<SoftwareInfoDTO>;
  applicationPiGroups: Array<ApplicationPiGroupDTO>;
  piTypes: Array<PiImplementation>;

  public modelStateMessages: IModelStateMessages = {};
  protected readonly unsubscribe$ = new Subject<void>();

  constructor(private ref: NbDialogRef<number>,
              private fb: FormBuilder,
              private translate: TranslateService,
              private applicationPiTimeService: TimePerformanceIndicatorData,
              private applicationPiTaskService: TaskPerformanceIndicatorData,
              private applicationPiErrorService: ErrorPerformanceIndicatorData,
              private applicationPiGroupService: ApplicationPiGroupData,
              private applicationSoftwareVersion: ApplicationSoftwareInfoService,
              private toastrService: NbToastrService) {
    this.form = this.fb.group({
      applicationId: this.fb.control(undefined, [Validators.required]),
      name: this.fb.control('', [Validators.required]),
      description: this.fb.control(undefined),
      minVersionId: this.fb.control(undefined),
      maxVersionId: this.fb.control(undefined),
      implementationKey: this.fb.control(undefined, [Validators.required]),
      formulaKey: this.fb.control('', [Validators.required]),
      color: this.fb.control(''),
      applicationPiGroupIds: this.fb.control([]),
    });
  }

  get type() {
    return this.form.get('implementationKey');
  }

  ngOnInit(): void {
    this.form.setValue({
      ...this.form.value,
      applicationId: this.applicationId,
    });

    this.applicationPiTimeService.implementations()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) {
          this.piTypes = data;
        }
      });

    this.applicationSoftwareVersion.list(1, 999, this.applicationId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data && data.items) {
          this.softwareInfo = data.items;
        }
      });

    this.applicationPiGroupService.list(1, 999, this.applicationId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data && data.items) {
          this.applicationPiGroups = data.items;
        }
      });
  }

  typeTranslation(value: PerformanceIndicatorType) {
    return this.translate.instant(fromPerformanceIndicatorType.getTranslationKey(value));
  }

  async createApplicationPi() {
    const formValue = this.form.value;

    const postData = {
      ...formValue,
    };

    let applicationPiService;

    switch (this.type.value) {
      case 'ApplicationTotalTimeIndicator':
        applicationPiService = this.applicationPiTimeService.create(postData);
        break;
      case 'ApplicationTaskIndicator':
        applicationPiService = this.applicationPiTaskService.create(postData);
        break;
      case 'ApplicationErrorIndicator':
        applicationPiService = this.applicationPiErrorService.create(postData);
        break;
      default:
        console.error('Unknown indicator type: ' + this.type.value);
    }

    applicationPiService.pipe(
      takeUntil(this.unsubscribe$),
      map(result => {
        // @ts-ignore
        if (result && result.implementationKey) {
          this.ref.close(result);
        }
      }),
      catchError(error => {
        if (error instanceof ModelStateError) {
          this.modelStateMessages = error.modelStateMessages;
        } else if (error instanceof ValidationError) {
          this.toastrService.danger('', error.data);
        }
        return of();
      })).subscribe();
  }
}
