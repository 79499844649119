import {ClaimRequirement} from '@core/claim-requirement';
import {Claim} from '@core/claim';

export const sessionViewClaimRequirement = ClaimRequirement.byAll(Claim.SessionView);
export const sessionEditClaimRequirement = ClaimRequirement.byAll(Claim.SessionEdit);
export const sessionRemoveClaimRequirement = ClaimRequirement.byAll(Claim.SessionRemove);

export const anySessionClaimRequirement = ClaimRequirement.byAny(
  sessionViewClaimRequirement,
  sessionEditClaimRequirement,
  sessionRemoveClaimRequirement);
