import { NbAuthOAuth2JWTToken, NbAuthService} from '@nebular/auth';
import {Injectable} from '@angular/core';
import {ClaimRequirement} from '@core/claim-requirement';
import {Observable, Subject} from 'rxjs';
import {Claim} from '@core/claim';
import {filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {ClaimTypes} from '@core/claim-types';
import { SeriousVrRoleType } from './interfaces/serious-vr/enums';
import { TranslateService } from '@ngx-translate/core';
import {EnumToArrayPipe} from '@core/utils/enum';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _onDestroy$: Subject<void> = new Subject();

  customRoleNameInstructor: string = null;
  customRoleNamePluralInstructor: string = null;
  customRoleNameManager: string = null;
  customRoleNamePluralManager: string = null;
  customRoleNameUser: string = null;
  customRoleNamePluralUser: string = null;
  enumToPipeArray = new EnumToArrayPipe();
  applicationClaims$ = this.nbAuthService.getToken().pipe(
    takeUntil(this._onDestroy$),
    switchMap(token => this.nbAuthService.onTokenChange().pipe(startWith(token))),
    map((token: NbAuthOAuth2JWTToken) => {
      let claims = [];
      if (token && token.getAccessTokenPayload) {
        const payload = token.getAccessTokenPayload();
        if (payload) {
          const claimLoad = payload[ClaimTypes.ApplicationClaims];
          if (claimLoad) {
            if (typeof claimLoad === 'string') {
              claims = [claimLoad];
            } else {
              claims = claimLoad;
            }
          }
          if (payload.CustomRoleName_Instructor) {
            this.customRoleNameInstructor = payload.CustomRoleName_Instructor;
          }
          if (payload.CustomRoleNamePlural_Instructor) {
            this.customRoleNamePluralInstructor = payload.CustomRoleNamePlural_Instructor;
          }
          if (payload.CustomRoleName_Manager) {
            this.customRoleNameManager = payload.CustomRoleName_Manager;
          }
          if (payload.CustomRoleNamePlural_Manager) {
            this.customRoleNamePluralManager = payload.CustomRoleNamePlural_Manager;
          }
          if (payload.CustomRoleName_User) {
            this.customRoleNameUser = payload.CustomRoleName_User;
          }
          if (payload.CustomRoleNamePlural_User) {
            this.customRoleNamePluralUser = payload.CustomRoleNamePlural_User;
          }
        }
      } else {
        this.customRoleNameInstructor = null;
        this.customRoleNamePluralInstructor = null;
        this.customRoleNameManager = null;
        this.customRoleNamePluralManager = null;
        this.customRoleNameUser = null;
        this.customRoleNamePluralUser = null;
      }
      return claims;
    }),
  );

  constructor(private nbAuthService: NbAuthService,
    private translate: TranslateService) {
  }

  getCustomRoleName(role: SeriousVrRoleType, plural: boolean): string {
    switch (role) {
      case SeriousVrRoleType.USER: return this.getUserRoleName(plural);
      case SeriousVrRoleType.INSTRUCTOR: return this.getInstructorRoleName(plural);
      case SeriousVrRoleType.MANAGER: return this.getManagerRoleName(plural);
      case SeriousVrRoleType.SERIOUSVR: return this.getAdminRoleName(plural);
    }
    return null;
  }

  getUserRoleName(plural: boolean) {
    const customName = plural ? this.customRoleNamePluralUser : this.customRoleNameUser;
    const translatedName = this.translate.instant(plural ? 'USERS.ROLES.PLURAL.USER' : 'USERS.ROLES.USER');
    return customName || translatedName;
  }

  getManagerRoleName(plural: boolean) {
    const customName = plural ? this.customRoleNamePluralManager : this.customRoleNameManager;
    const translatedName = this.translate.instant(plural ? 'USERS.ROLES.PLURAL.MANAGER' : 'USERS.ROLES.MANAGER');
    return customName || translatedName;
  }

  getInstructorRoleName(plural: boolean) {
    const customName = plural ? this.customRoleNamePluralInstructor : this.customRoleNameInstructor;
    const translatedName = this.translate.instant(plural ? 'USERS.ROLES.PLURAL.INSTRUCTOR' : 'USERS.ROLES.INSTRUCTOR');
    return customName || translatedName;
  }

  getAdminRoleName(plural: boolean) {
    const translatedName = this.translate.instant(plural ? 'USERS.ROLES.PLURAL.SERIOUSVR' : 'USERS.ROLES.SERIOUSVR');
    return translatedName;
  }

  selectCurrentClaims(): Observable<Claim[]> {
    return this.applicationClaims$
      .pipe(
        filter(x => x !== null),
        map(claims => claims ? claims : []),
      );
  }

  selectHasAccess(claimRequirement: ClaimRequirement<Claim>): Observable<boolean> {
    return this.selectCurrentClaims()
      .pipe(
        map(claims => {
          return claimRequirement.hasAccess(claims);
        }),
      );
  }

  RoleEnumToSelectArray() {
    const roles = this.enumToPipeArray.transform(SeriousVrRoleType) as any[];
    return roles.map(role => {
      return {
        value: role.id,
        title: this.getCustomRoleName(role.id, false),
      };
    });
  }
}
